import React, { Component } from 'react';

import ApiRequest from '../../../utils/apiRequest';

function withComponentData(PartentComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                componentData: null
            };

            this.filter = null;
            this.prevFilter = null;

            const { componentConfig: { service } } = props;
            this.req = new ApiRequest(service);
        }

        loadComponentData(filter) {

            if (this.prevFilter !== filter && this.req.busy) {
                this.req.abort("", { silent: true });
            }

            this.prevFilter = filter;
            this.req.filter = filter;
            this.req.send()
                .then(this.handleResponse.bind(this))
                .catch(console.error);
        }

        handleResponse(componentData) {
            this.setState({ componentData });
        }

        handleRefreshComponentData() {
            this.loadComponentData.call(this, this.prevFilter);
        }

        render() {
            const { componentData } = this.state;

            return <PartentComponent {...this.props}
                componentData={componentData}
                initComponentData={this.loadComponentData.bind(this)}
                setFilter={this.loadComponentData.bind(this)}
                refreshComponentData={this.handleRefreshComponentData.bind(this)} />;
        }
    };
}

export default withComponentData;