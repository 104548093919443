import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

class ComponentNotFound extends Component {
    render() {
        const { classes, componentConfig: { type } } = this.props;

        return (
            <div className={classes.componentNotFound}>
                <ErrorIcon color="error" fontSize="large" />
                <Typography variant="h4">Komponente: {type} nicht gefunden</Typography>
            </div>
        );
    }
}

ComponentNotFound.propTypes = {
    classes: PropTypes.object.isRequired,
    componentConfig: PropTypes.object.isRequired
};

export default withStyles(styles)(ComponentNotFound);