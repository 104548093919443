import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import BaseDialog from './base';

class FormDialog extends Component {
    constructor() {
        super();

        this.formRef = null;
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;

        onSubmit();
    }

    triggerSubmit = () => {
        this.formRef.submit();
    }

    render() {
        const { onClose, dialogOpen, title, text, ...others } = this.props;
        delete others.onSubmit;

        return (
            <BaseDialog onClose={onClose} aria-labelledby="form-dialog" open={dialogOpen} {...others}>
                <DialogTitle id="form-dialog">{title}</DialogTitle>
                {text && <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                </DialogContent>}
                <DialogActions>
                    <Button onClick={onClose}>
                        Abbrechen
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" autoFocus>
                        Bestätigen
                    </Button>
                </DialogActions>
            </BaseDialog>
        );
    }
}

FormDialog.propTypes = {
    suppressCloseOnSubmit: PropTypes.bool,
    dialogOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
    FormProps: PropTypes.object
};

export default FormDialog;