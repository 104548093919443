import ApiRequest from '../../../utils/apiRequest';

export default class CalendarEventSource {
    constructor(serviceConfig) {
        this.serviceConfig = serviceConfig;
        this.fetchEventsReq = null;
    }

    async fetchEvents(start, end) {
        if (this.fetchEventsReq) {
            this.fetchEventsReq.abort();
        }
        const service = this.serviceConfig.fetchEvents;
        this.fetchEventsReq = new ApiRequest(service);
        this.fetchEventsReq.addToQueryString('start', start);
        this.fetchEventsReq.addToQueryString('end', end);
        this.fetchEventsReq.abort("", { silent: true });
        return this.fetchEventsReq.send();
    }
}