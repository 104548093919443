import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ChangePasswordDialog from './changePasswordDialog';
import EditAccountDialog from './editAccountDialog';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        minWidth: 150
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: theme.spacing() + 'px -' + theme.spacing() + 'px',
        "& button": {
            margin: '0px ' + theme.spacing() + 'px'
        }
    },
    fullWidthButton: {
        width: '100%'
    }
});

class PopperContent extends Component {
    constructor() {
        super();

        this.state = {
            changePasswordDialogOpen: false,
            editAccountDialogOpen: false
        };
    }

    getTitle = () => {
        const { user: { username, person } } = this.context;

        if (!person) {
            return username;
        }

        const { first_name, last_name } = person;

        return first_name + " " + last_name;
    }

    handleClickAway = () => {
        const { changePasswordDialogOpen, editAccountDialogOpen } = this.state;

        if (changePasswordDialogOpen || editAccountDialogOpen) {
            return;
        }

        const { onClose } = this.props;
        onClose();
    }

    handleLogout = () => {
        const { onClose, onLogout } = this.props;

        onClose();
        onLogout();
    }

    handleChangePasswordClick = () => {
        this.setState({ changePasswordDialogOpen: true });
    }

    handleChangePasswordDialogClose = () => {
        setTimeout(() => {
            this.setState({ changePasswordDialogOpen: false });
        }, 0);
    }

    handleEditAccountDialogClick = () => {
        this.setState({ editAccountDialogOpen: true });
    }

    handleEditAccountDialogClose = () => {
        setTimeout(() => {
            this.setState({ editAccountDialogOpen: false });
        }, 0);
    }

    render() {
        const { classes, onUserUpdate, onUserPasswordChange } = this.props;
        const { changePasswordDialogOpen, editAccountDialogOpen } = this.state;
        const title = this.getTitle.call(this);

        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                <Paper className={classes.paper}>
                    <Typography variant="h5">{title}</Typography>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" onClick={this.handleChangePasswordClick}>
                            Passwort ändern
                        </Button>
                        <Button variant="contained" onClick={this.handleEditAccountDialogClick}>
                            Konto
                        </Button>
                    </div>
                    <hr />
                    <Button variant="contained" className={classes.fullWidthButton} onClick={this.handleLogout}>
                        Logout
                    </Button>
                    <ChangePasswordDialog
                        dialogOpen={changePasswordDialogOpen}
                        onUserPasswordChange={onUserPasswordChange}
                        onClose={this.handleChangePasswordDialogClose}
                    />
                    <EditAccountDialog
                        dialogOpen={editAccountDialogOpen}
                        onUserUpdate={onUserUpdate}
                        onClose={this.handleEditAccountDialogClose}
                    />
                </Paper>
            </ClickAwayListener>
        );
    }
}

PopperContent.contextTypes = {
    user: PropTypes.object
};

PopperContent.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default withStyles(styles)(PopperContent);