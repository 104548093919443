import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailOutline from '@material-ui/icons/MailOutline';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import Dialog from './dialog';

const styles = () => ({
    container: {
        position: 'relative'
    },
    progress: {
        position: 'absolute',
        left: 6,
        top: 6
    }
});

class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            unreadCount: null
        };
    }

    handleClick = () => {
        this.setState({ dialogOpen: true });
    }

    handleClose = () => {
        this.setState({ dialogOpen: false });
    }

    handleNewMessages = unreadCount => {
        this.setState({ unreadCount });
    }

    render() {
        const { dialogOpen, unreadCount, isLoading } = this.state;
        const { classes, loggedIn } = this.props;

        const hasMailConfig = Boolean(this.context.config.systemServices.mail);

        if (!loggedIn || !hasMailConfig) {
            return null;
        }

        const { config: { systemServices: { mail } } } = this.context;

        const disableButton = unreadCount === null;

        return (
            <div className={classes.container}>
                <IconButton color="inherit" onClick={this.handleClick} disabled={disableButton}>
                    <Badge badgeContent={unreadCount} color="secondary">
                        <MailOutline />
                    </Badge>
                </IconButton>
                {isLoading && <CircularProgress
                    color="secondary"
                    size={35}
                    className={classes.progress}
                />}
                <Dialog
                    dialogOpen={dialogOpen}
                    onNewMessages={this.handleNewMessages}
                    onClose={this.handleClose}

                    systemServices={mail}
                />
            </div>
        );
    }
}

Notifications.contextTypes = {
    config: PropTypes.shape({
        systemServices: PropTypes.shape({
            mail: PropTypes.shape({
                inbox: PropTypes.object
            })
        })
    })
};

Notifications.propTypes = {
    classes: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool
};

export default withStyles(styles)(Notifications);