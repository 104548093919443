import { DrawerWidth } from '../contentContainer/styles';

export default theme => ({
    container: {
        height: "100%"
    },
    content: {
        position: "fixed",
        top: theme.spacing(2) + 32,
        left: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            left: theme.spacing(9) + 1,
        },
        right: 0,
        bottom: 0,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentShift: {
        position: "fixed",
        top: theme.spacing(2) + 32,
        left: DrawerWidth,
        right: 0,
        bottom: 0,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    componentWrapper: {
        padding: theme.spacing(),
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: 'hidden'
    },
    componentNotFound: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(2)
    },
    fab: {
        flex: "0 0 auto",
        alignSelf: "flex-end"
    },
});