import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    card: {
        marginTop: theme.spacing()
    },
    firstButton: {
        marginLeft: 'auto'
    }
});

class ArrayItem extends Component {
    constructor(props) {
        super(props);

        const { onDropIndexClick, index } = props;

        this.handleRemoveClick = onDropIndexClick(index);
    }

    render() {
        const { classes, children, hasRemove } = this.props;

        return (
            <Card elevation={8} className={classes.card}>
                <CardContent>
                    {children}
                </CardContent>
                <CardActions>
                    {hasRemove && <Button onClick={this.handleRemoveClick} className={classes.firstButton}>
                        <DeleteIcon />
                    </Button>}
                </CardActions>
            </Card>
        );
    }
}

ArrayItem.propTypes = {
    classes: PropTypes.object.isRequired,
    index: PropTypes.number,
    children: PropTypes.object,
    hasRemove: PropTypes.bool,
    onDropIndexClick: PropTypes.func.isRequired
};

export default withStyles(styles)(ArrayItem);