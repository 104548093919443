import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';

import Alert from './notificationApi';

class Notifications extends Component {
    constructor(props) {
        super(props);

        const { enqueueSnackbar } = this.props;

        const action = this.getActionRenderer();

        const defaultSnackbarOptions = {
            action
        };

        Alert.setNotistackApi({
            enqueueSnackbar
        }, defaultSnackbarOptions);
    }

    getActionRenderer = key => () => {
        const { closeSnackbar } = this.props;

        return <Button size="small" onClick={() => closeSnackbar(key)}>
            schließen
        </Button>;
    }

    render() {
        return <div className="notification" />;
    }
}

Notifications.propTypes = {
    closeSnackbar: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(Notifications);