const FormBaseStyles = () => ({
    hidden: {
        display: "none"
    }
});

const FormStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    hidden: {
        display: "none"
    },
    form: {
        flex: 1,
        overflow: "auto",
        width: '100%'
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    formContainerPaper: {
        position: "absolute",
        top: "2%",
        bottom: 0,
        left: 0,
        right: 0,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline"

    },
    fab: {
        flex: "0 0 auto",
        alignSelf: "flex-end"
    }
});

export {
    FormBaseStyles,
    FormStyles
};

export default FormStyles;