import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';

const styles = () => ({
    fixedHeight: {
        height: '83vh'
    }
});

class BaseDialog extends Component {
    render() {
        const { classes, fixedHeight, ...others } = this.props;

        const paperClassName = cx({
            [classes.fixedHeight]: fixedHeight
        });

        return (
            <Dialog {...others} classes={{ paper: paperClassName }} />
        );
    }
}

BaseDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    fixedHeight: PropTypes.bool
};

export default withStyles(styles)(BaseDialog);