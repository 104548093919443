import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

class TitleField extends Component {
    render() {
        const { title } = this.props;
        return (
            <Typography variant="h3" gutterBottom>
                {title}
            </Typography>
        );
    }
}

TitleField.propTypes = {
    title: PropTypes.string.isRequired
};

export default TitleField;