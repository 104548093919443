import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

class Glyphicon extends Component {
    formatBsToMaUI(bsName) {
        if (bsName === "trash") return "delete";
        if (bsName === "pencil") return "edit";
        return bsName;
    }

    render() {
        let { /* classes, */ glyph } = this.props;

        glyph = this.formatBsToMaUI(glyph);
        return (
            <Icon>
                {glyph}
            </Icon>
        );
    }
}

Glyphicon.propTypes = {
    /* classes: PropTypes.object.isRequired, */
    glyph: PropTypes.string.isRequired
};

export default withStyles(styles)(Glyphicon);