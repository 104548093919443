import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormDialog from '../../../dialog/form';

const FormConfig = {
    "schema": {
        "type": "object",
        "properties": {
            "old_password": {
                "type": "string",
                "title": "Altes Passwort",
                "format": "password"
            },
            "new_password": {
                "type": "string",
                "title": "Neues Passwort",
                "format": "password"
            },
            "verify_new_password": {
                "type": "string",
                "title": "Neues Passwort bestätigen",
                "format": "password"
            }
        }
    },
    "uiSchema": {
        "ui:order": [
            "old_password",
            "new_password",
            "verify_new_password"
        ],
        "old_password": {
            "size": {
                "sm": 12
            }
        },
        "new_password": {
            "size": {
                "sm": 6
            }
        },
        "verify_new_password": {
            "size": {
                "sm": 6
            }
        }
    }
};

class ChangePasswordDialog extends Component {
    constructor() {
        super();

        this.formRef = null;
    }

    render() {
        const { onClose, dialogOpen, onUserPasswordChange } = this.props;

        return (
            <FormDialog
                title="Passwort ändern"
                onClose={onClose}
                onSubmit={onUserPasswordChange}
                FormProps={FormConfig}
                dialogOpen={dialogOpen}
                suppressCloseOnSubmit
            />
        );
    }
}

ChangePasswordDialog.propTypes = {
    dialogOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default ChangePasswordDialog;