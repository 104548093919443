import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

import MailSender from './sender';

import ApiRequest from '../../../utils/apiRequest';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    htmlContent: {
        border: 0,
        height: '100%'
    },
    plainContent: {
        whiteSpace: 'pre-line',
        overflow: 'auto'
    }
});

class MailContent extends Component {
    constructor(props, context) {
        super(props);

        this.state = {
            message: null,
            isLoading: false
        };

        this.messageRequest = null;
        this.initMail(context);
    }

    componentDidUpdate(prevProps) {
        const { selectedMessageId } = this.props;

        if (_.isEqual(prevProps.selectedMessageId, selectedMessageId)) {
            return;
        }

        this.queryMessage(selectedMessageId);
    }

    initMail = (context = this.context) => {
        const { config: { systemServices: { mail: { inbox: { message } } } } } = context;
        this.messageRequest = new ApiRequest(message);
    }

    queryMessage = id => {
        this.setState({ isLoading: true });
        this.messageRequest.send({ id })
            .then(this.handleQueryMessageResponse);
    }

    handleQueryMessageResponse = message => {
        this.setState({ message, isLoading: false });
    }

    render() {
        const { message, isLoading } = this.state;

        if (!message) {
            return null;
        }

        const { classes } = this.props;
        const { subject, content } = message;

        const isHtmlContent = Boolean(content['text/html']);

        return (
            <Paper className={classes.paper}>
                {isLoading ? <LinearProgress variant="query" color="secondary" />
                    : <Fragment>
                        <MailSender {...message} />
                        <Typography variant="subtitle2">{subject}</Typography>
                        <Divider />
                        {isHtmlContent ?
                            <iframe className={classes.htmlContent} title="mail-content" srcDoc={content['text/html']} />
                            : <div className={classes.plainContent}>{content['text/plain']}</div>}
                    </Fragment>}
            </Paper>
        );
    }
}

MailContent.contextTypes = {
    config: PropTypes.object.isRequired
};

MailContent.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedMessageId: PropTypes.string
};

export default withStyles(styles)(MailContent);