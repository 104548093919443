import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Glyph from '../../glyph/';

class ListMenuItem extends Component {

    async handleClick() {
        const { buttonConfig: { onClick }, onButtonClick, item, onMenuClose } = this.props;

        await onButtonClick(onClick, item);
        onMenuClose();
    }

    render() {
        const { buttonConfig: { label, glyph, tooltip } } = this.props;

        const tooltipOpen = tooltip ? undefined : false;

        return (
            <Tooltip title={tooltip || ""} placement="left" open={tooltipOpen}>
                <MenuItem onClick={this.handleClick.bind(this)}>
                    {glyph &&
                        <ListItemIcon>
                            <Glyph glyph={glyph} />
                        </ListItemIcon>}
                    {label && <ListItemText primary={label} />}
                </MenuItem>
            </Tooltip>
        );
    }
}

ListMenuItem.propTypes = {
    buttonConfig: PropTypes.shape({
        label: PropTypes.string,
        glyph: PropTypes.string
    }).isRequired,
    onButtonClick: PropTypes.func.isRequired,
    onMenuClose: PropTypes.func.isRequired,
    item: PropTypes.object
};

export default ListMenuItem;