import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { withStyles } from '@material-ui/styles/';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    listItemPrimaryText: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    spacer: {
        color: theme.palette.primary.main,
        position: 'absolute',
        left: theme.spacing(-2),
        top: 17,
        fontSize: 11
    },
    senderTitle: {
        marginRight: theme.spacing(2)
    },
    dateTitle: {
        minWidth: 65
    }
});

class MailPreviewItem extends Component {

    getSender = () => {
        const { senders } = this.props;

        return senders[0].name || senders[0].email;
    }

    handleClick = () => {
        const { onMessageSelected, id } = this.props;

        onMessageSelected && onMessageSelected(id);
    }

    render() {
        const { subject, flags, date, classes } = this.props;

        const isUnread = flags && !flags['\\Seen'];
        const formatedDate = Moment(date).calendar();

        const sender = this.getSender();

        return (
            <ListItem
                onClick={this.handleClick}
                disableGutters
            >
                <div className={classes.spacer}>
                    {isUnread ? "\u2b24" : null}
                </div>
                <ListItemText secondary={subject} classes={{ primary: classes.listItemPrimaryText }}>
                    <Typography noWrap className={classes.senderTitle}>{sender}</Typography>
                    <Typography noWrap className={classes.dateTitle} variant="caption">{formatedDate}</Typography>
                </ListItemText>
            </ListItem>
        );
    }
}

MailPreviewItem.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string,
    senders: PropTypes.array,
    subject: PropTypes.string,
    flags: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    date: PropTypes.string,
    onMessageSelected: PropTypes.func
};

export default withStyles(styles)(MailPreviewItem);