export default function SetUndefienedToNull(formData) {
    if (formData === null) {
        return formData;
    }

    if (formData === undefined) {
        return null;
    }

    if (Array.isArray(formData)) {
        return formData.map(SetUndefienedToNull);
    }

    if (typeof formData === 'object') {
        return Object.keys(formData)
            .reduce((acc, curr) => {
                acc[curr] = SetUndefienedToNull(formData[curr]);
                return acc;
            }, {});
    }

    return formData;
}