import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from "react-router-dom";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { ThemeProvider, withStyles } from '@material-ui/styles';

import { AlertProvider } from './context/alert';
import { ConfigProvider } from './context/config';
import { UserProvider } from './context/user';

import ContentContainer from './components/contentContainer';
import NotificationApi from './components/header/toolbar/notifications/notificationApi';

import I18n from './utils/i18n';
import ApiRequest from './utils/apiRequest';
import StyleHandler from './utils/styleHandler';

import './utils/polyfills/';
import './extensions/';

const styles = () => ({
    message: {
        color: '#fff'
    }
});

class App extends Component {
    constructor(props) {
        super(props);

        this.styleHandler = new StyleHandler();
        this.styleHandler.on('ThemeChange', muiTheme => this.setState({ muiTheme }));

        this.state = {
            loggedIn: false,
            user: null,
            navigationConfig: null,
            muiTheme: this.styleHandler.currentTheme
        };

        const { config } = this.props;

        this.i18n = new I18n(config.i18n);

        this.alert = new NotificationApi();

        ApiRequest.setDataSources(config.dataSources);
        ApiRequest.setAlert(this.alert);
    }

    getChildContext() {
        return {
            config: this.props.config,
            alert: this.alert,
            user: this.state.user
        };
    }

    handleLogin = (user) => {
        ApiRequest.setUser(user);
        NotificationApi.alterConsole(user);

        this.alert.info("Welcome " + user.username);
        this.i18n.setL10n("de");
        user.l10n = "de";

        this.setState({
            loggedIn: true,
            user
        });
        this.loadNavigationConfig();
        this.styleHandler.changeTheme(user.theme);
    }

    handleLogout = () => {
        localStorage.removeItem('manager_login_jwt_token');
        this.setState({ user: null, loggedIn: false, navigationConfig: null });
    }

    handleUserUpdate = data => {
        const { config: { systemServices: { updateUser } } } = this.props;

        const request = new ApiRequest(updateUser);
        request.send(data.formData)
            .then(this.handleUserUpdateResponse);
    }

    handleUserUpdateResponse = user => {
        this.setState({ user });
        this.styleHandler.changeTheme(user.theme);
    }

    handleUserPasswordChange = data => {
        const { config: { systemServices: { changePassword } } } = this.props;

        const { user_id } = this.state.user;
        const request = new ApiRequest(changePassword);
        request.send({ ...data.formData, user_id })
            .then(this.handleLogout);
    }

    loadNavigationConfig = () => {
        const { config } = this.props;
        let req = new ApiRequest(config.systemServices.navigation);
        req.send()
            .then(navigationConfig => this.setState({ navigationConfig }))
            .catch(console.error);
    }

    render() {
        const { classes, config } = this.props;
        const { loggedIn, navigationConfig, muiTheme, user } = this.state;

        return (
            <AlertProvider alert={this.alert}>
                <ConfigProvider value={config}>
                    <UserProvider value={user}>
                        <ThemeProvider theme={muiTheme}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <SnackbarProvider
                                    maxSnack={3}
                                    classes={{ message: classes.message }}
                                    preventDuplicate
                                >
                                    <div className="app">
                                        <BrowserRouter>
                                            <ContentContainer navigationConfig={navigationConfig}
                                                onLogin={this.handleLogin}
                                                onLogout={this.handleLogout}
                                                onUserUpdate={this.handleUserUpdate}
                                                onUserPasswordChange={this.handleUserPasswordChange}
                                                loggedIn={loggedIn} />
                                        </BrowserRouter>
                                    </div>
                                </SnackbarProvider>
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </UserProvider>
                </ConfigProvider>
            </AlertProvider>
        );
    }
}

App.childContextTypes = {
    config: PropTypes.object,
    alert: PropTypes.object,
    user: PropTypes.object
};

App.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired
};

export default withStyles(styles)(App);