import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { withStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import SecondaryListItemContent from './secondaryListItemContent';

import ST from '../../utils/st';

const styles = () => ({
    primaryText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

const ListFooter = props => {
    const { classes, style, items, componentConfig } = props;

    if (!componentConfig?.footerDisplayTemplate) {
        return null;
    }

    const { primary, secondary, subTitle } = ST(componentConfig.footerDisplayTemplate, {
        Moment,
        items
    });

    const secondaryText = <SecondaryListItemContent secondary={secondary} subTitle={subTitle} />;

    return (
        <Fragment>
            <Divider />
            <MuiListItem style={style}>
                <ListItemText primary={primary} secondary={secondaryText} className={classes.primaryText} />
            </MuiListItem>
        </Fragment>
    );
};

ListFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    componentConfig: PropTypes.shape({
        buttonSplitedConfig: PropTypes.object,
        footerDisplayTemplate: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ])
    }).isRequired,
    style: PropTypes.object,
    items: PropTypes.array.isRequired
};

export default withStyles(styles)(ListFooter);