import React, { Component } from 'react';

function asComponent(WrappedComponent) {
    class AsComponent extends Component {

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return AsComponent;
}

export default asComponent;