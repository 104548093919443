import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DragAndDropList from './dragAndDropList/';
import MergeOrderOptionsWithFilterData from './mergeOrderOptionsWithFilterData';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        minWidth: 150
    }
});

const PopperContent = props => {
    const { classes, onClose, orderOptions, filterData, onSubmit } = props;
    const items = MergeOrderOptionsWithFilterData(orderOptions, filterData);

    const handleClickAway = () => {
        onClose();
    };

    const handleSubmit = orderOptions => {
        const orderBy = orderOptions
            .filter(orderOption => orderOption.active)
            .map(orderOption => ([
                orderOption.value,
                orderOption.desc ? "DESC" : "ASC"
            ]));

        const options = {
            ...filterData?.options || {},
            orderBy
        };

        onSubmit({
            ...filterData,
            options
        });
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Paper className={classes.paper} elevation={8}>
                <Typography variant="caption">Sortieren nach:</Typography>
                <DragAndDropList items={items} onSubmit={handleSubmit} />
            </Paper>
        </ClickAwayListener>
    );
};

PopperContent.contextTypes = {
    user: PropTypes.object
};

PopperContent.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    orderOptions: PropTypes.array.isRequired,
    filterData: PropTypes.object,
    onSubmit: PropTypes.func
};

export default withStyles(styles)(PopperContent);