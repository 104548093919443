import _ from 'lodash';
import AlterConsole from './alterConsole';

let NotiStackApi;
let DefaultSnackbarOptions;

const DefaultAnchorOrigins = {
    vertical: 'bottom',
    horizontal: 'left',
};

export default class NotificationApi {/* 
    constructor() {
        console.error = err => {
        };
    } */

    get enqueueSnackbar() {
        if (!NotiStackApi) return console.log;
        return (message, options) => {

            const mergedOptions = _.defaultsDeep(options, {
                anchorOrigin: DefaultAnchorOrigins,
                ...DefaultSnackbarOptions
            });

            NotiStackApi.enqueueSnackbar(message, mergedOptions);
        };
    }

    static setNotistackApi(api, defaultSnackbarOptions) {
        NotiStackApi = api;
        DefaultSnackbarOptions = defaultSnackbarOptions;
    }

    static alterConsole(user) {
        AlterConsole(user, this);
    }

    success(message) {
        this.enqueueSnackbar(message, {
            variant: 'success'
        });
    }

    info(message) {
        this.enqueueSnackbar(message, {
            variant: 'info'
        });
    }

    warn(message) {
        this.enqueueSnackbar(message, {
            variant: 'warning'
        });
    }

    error(message) {
        this.enqueueSnackbar(message, {
            variant: 'error'
        });
    }
};