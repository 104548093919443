import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import RefreshIcon from '@material-ui/icons/Refresh';

import MailPreviewItem from './previewItem';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        paddingLeft: 0,
        width: 300,
        display: 'flex',
        flexDirection: 'column'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(3)
    },
    list: {
        overflow: 'auto',
        height: '83vh',
        paddingLeft: theme.spacing(3),
        flex: 1
    }
});

class MailPreview extends Component {

    handleRefreshClick = evt => {
        const { onRefreshClick } = this.props;

        onRefreshClick(evt);
    }

    renderMessage = message => {
        const { onMessageSelected } = this.props;

        return [
            <Divider key={"divider-" + message.id} />,
            <MailPreviewItem
                key={message.id}
                {...message}
                onMessageSelected={onMessageSelected}
            />
        ];
    }

    render() {
        const { classes, messages } = this.props;

        const title = "E-Mails";
        const hasMessages = messages && messages.length > 0;
        const noMessageText = "keine Nachrichten";

        return (
            <Paper className={classes.paper}>
                <div className={classes.headerContainer}>
                    <Typography variant="h3">{title}</Typography>
                    <IconButton onClick={this.handleRefreshClick}>
                        <RefreshIcon />
                    </IconButton>
                </div>
                {hasMessages ?
                    <List className={classes.list}>{messages.map(this.renderMessage)}</List>
                    : <Typography>{noMessageText}</Typography>}
            </Paper>
        );
    }
}

MailPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    onMessageSelected: PropTypes.func,
    messages: PropTypes.array
};

export default withStyles(styles)(MailPreview);