import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Input from './input';

import asComponent from '../../../../hoc/asComponent';
import ApiRequest from '../../../../utils/apiRequest';
import ObjectKeyMapping from '../../../../utils/objKeysMapping';

const useStyles = makeStyles({
    autoComplete: {
        width: '100%'
    },
});

function loadOptions(options, filter = "") {
    const { service, filterKey } = options;

    const req = new ApiRequest(service);
    let qtype = filterKey;
    if (Array.isArray(filterKey)) {
        qtype = filterKey.join(',');
    }
    req.addToQueryString('qtype', qtype);
    req.addToQueryString('q', filter);
    return req.send();
}

function ItemToString(options, item) {
    const { displayKey, filterKey } = options;

    if (!item) return '';
    return ObjectKeyMapping(item, displayKey || filterKey);
}

function HandleChange(options, onChange, item) {
    const { IDKey, filterKey } = options;

    if (!item) {
        onChange(undefined);
        return;
    }

    const value = item[IDKey];
    if (value === null || value === undefined) {
        onChange(item[filterKey]);
        return;
    }

    onChange(value);
}

function GetInitialSelectedItem(options, value) {
    const { service } = options;

    if (!value) {
        return;
    }

    const uri = service.uri + "/" + value;
    const req = new ApiRequest({ ...service, uri });
    return req.send();
}

function CheckOptionSelected(...args) {
    console.log(args);
}

function AsyncAutoComplete(props) {
    const { id, options, value, onChange, ...others } = props;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!options.service) {
            console.warn("no service for async select field provided");
            return;
        }

        const hasNoSelectedItem = selectedItem === null || selectedItem === undefined;
        const hasValue = value !== null && value !== undefined;
        if (hasNoSelectedItem && hasValue) {
            (async () => {
                setLoading(true);
                const fetchedItem = await GetInitialSelectedItem(options, value);
                setSelectedItem(fetchedItem);
                setLoading(false);
            })();
            return;
        }

        const hasSelectedItem = selectedItem !== null && selectedItem !== undefined;
        if (hasSelectedItem) {
            const slectedItemInputValue = ItemToString(options, selectedItem);
            if (filter === slectedItemInputValue) {
                return;
            }
        }
        (async () => {
            setLoading(true);
            const fetchedItems = await loadOptions(options, filter);
            setData(fetchedItems);
            setLoading(false);
        })();

    }, [options, value, selectedItem, filter]);

    const classes = useStyles(props);

    const handleChange = (evt, item) => {
        HandleChange(options, onChange, item);
        setSelectedItem(item);
    };

    const handleInputChange = (evt, val) => {
        setFilter(val);
    };

    const handleFocus = async () => {
        setLoading(true);
        const fetchedItems = await loadOptions(options, filter);
        setData(fetchedItems);
        setLoading(false);
    };

    return (
        <Autocomplete
            id={id}
            className={classes.autoComplete}
            options={data}
            value={selectedItem}
            getOptionLabel={item => ItemToString(options, item)}
            getOptionSelected={CheckOptionSelected}
            onInputChange={handleInputChange}
            onChange={handleChange}
            onFocus={handleFocus}
            disableListWrap
            renderInput={params => <Input
                loading={loading}
                params={params}
                options={options}
                {...others}
            />}
        />
    );
}

AsyncAutoComplete.propTypes = {
    id: PropTypes.string,
    options: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

export default asComponent(AsyncAutoComplete);