import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

import './styles/';
import './extensions/';

fetch("/config.json")
    .then(res => res.json())
    .then((res) => {
        ReactDOM.render(<App config={res} />, document.getElementById('root'));
    })
    .catch((err) => console.log(err));
