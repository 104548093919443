import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import MUIListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Glyph from '../../glyph';

class ListTitle extends Component {

    handleClick(...args) {
        const {
            onClick, history,
            item: { component }
        } = this.props;

        onClick && onClick(...args);

        if (component) {
            history.push("/" + component);
        }
    }

    render() {
        const {
            collapseOpen, expandable,
            item: { glyph, label }
        } = this.props;

        return (
            <MUIListItem button onClick={this.handleClick.bind(this)} >
                {glyph && <ListItemIcon><Glyph glyph={glyph} /></ListItemIcon>}
                <ListItemText primary={label} />
                {expandable ?
                    collapseOpen ? <ExpandLess /> : <ExpandMore />
                    : null
                }
            </MUIListItem>
        );
    }
}

ListTitle.propTypes = {
    item: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    collapseOpen: PropTypes.bool,
    expandable: PropTypes.bool,
    onClick: PropTypes.func
};

export default withRouter(ListTitle);