import Moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/de';

export default class I18n {
    constructor(config) {
        this.config = config;
        this.l10n = "en";
    }

    setL10n(l10n) {
        this.l10n = l10n;
        Moment.locale(l10n);
    }
}