import Moment from 'moment';

const Format = (val, format) => {
    if (!format) return val;
    if (format === "date") return Moment(val).format("L");
    if (format === "date-time") return Moment(val).format("L LT");
    if (format.indexOf("custom-date") !== -1) {
        let dateFormat = format.substring(format.indexOf("_") + 1);
        return Moment(val).format(dateFormat);
    }
    return val;
};

const EvalDotNotation = (source, key) => {
    const splittedKeys = key.split('.');
    if (splittedKeys.length <= 1) {
        return null;
    }

    let result = source;
    splittedKeys.forEach(splittedKey => {
        if(result === null || result === undefined) {
            return null;
        }
        result = result[splittedKey];
    });

    return result;
};

export default (source, keys) => {
    if (!keys) return "";
    if (!Array.isArray(keys)) {
        keys = [keys];
    }

    return keys.reduce((acc, curr) => {
        if (!Array.isArray(curr)) {
            curr = [curr];
        }

        const key = curr[0];

        if (key === null || key === undefined) {
            return acc;
        }

        const nestedValue = EvalDotNotation(source, key);
        if (nestedValue) {
            acc += Format(nestedValue, curr[1]);
            return acc;
        }

        if (source[key] === undefined) {
            acc += key;
            return acc;
        }

        if (source[key]) {
            acc += Format(source[key], curr[1]);
            return acc;
        }

        return acc;
    }, "");
};