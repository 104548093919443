/* eslint no-extend-native: 0 */
String.prototype.isValidJSON = String.prototype.isValidJSON || function () {
    try {
        JSON.parse(this);
        return true;
    } catch (err) { }
    return false;
};

String.prototype.isValidURI = String.prototype.isValidURI || function () {
    let pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    if (!pattern.test(this)) {
        return false;
    }
    return true;
};