import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class MobileDetail extends Component {

    renderEvent = (event, index) => {

        const { title, start } = event;
        const startLabel = Moment.utc(start).local().format("L LT");

        return (
            <Fragment key={index}>
                <Divider />
                <Typography variant="h3">{title}</Typography>
                <Typography>{startLabel}</Typography>
            </Fragment>
        );
    }

    render() {
        const { selectedEvents } = this.props;

        if (!selectedEvents) {
            return null;
        }

        return selectedEvents.map(this.renderEvent);
    }
}

MobileDetail.propTypes = {
    selectedEvents: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            start: PropTypes.object
        }))
};

export default MobileDetail;