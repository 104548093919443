import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Mail from './mail/';
import Notifications from './notifications/';
import User from './user';

const styles = () => ({
    hide: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    }
});

class Toolbar extends Component {
    render() {
        const { classes, drawerOpen, handleDrawerOpen, loggedIn, onLogout, onUserUpdate, onUserPasswordChange } = this.props;

        const iconButtonClassName = cx(classes.menuButton, {
            [classes.hide]: drawerOpen,
        });

        return (
            <MuiToolbar disableGutters={!drawerOpen} variant="dense">
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={handleDrawerOpen}
                    className={iconButtonClassName}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap>
                    manager
                </Typography>
                <div className={classes.grow} />
                <Mail loggedIn={loggedIn} />
                <Notifications />
                <User
                    loggedIn={loggedIn}
                    onLogout={onLogout}
                    onUserUpdate={onUserUpdate}
                    onUserPasswordChange={onUserPasswordChange}
                />
            </MuiToolbar>
        );
    }
}

Toolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    drawerOpen: PropTypes.bool,
    handleDrawerOpen: PropTypes.func,
    onLogout: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool,
    onUserUpdate: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default withStyles(styles)(Toolbar);