import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import IsLoadingInput from './isLoadingInput';

class BaseInput extends Component {

    handleChange(evt) {
        const { onChange, onChangeEvent, options } = this.props;
        let { target: { value } } = evt;

        if (onChangeEvent) {
            onChangeEvent(evt);
        }

        if (onChange) {
            onChange(value === "" ? options.emptyValue : value);
        }
    }

    getInputProps(options, schema, inputProps) {
        if (options.inputType) {
            inputProps.type = options.inputType;
        } else if (!inputProps.type) {
            // If the schema is of type number or integer, set the input type to number
            if (schema.type === "number") {
                inputProps.type = "number";
            } else if (schema.type === "integer") {
                inputProps.type = "number";
                // Since this is integer, you always want to step up or down in multiples
                // of 1
                inputProps.step = "1";
            } else {
                inputProps.type = "text";
            }
        }

        /* if (schema.type === "number") {
            // Setting step to 'any' fixes a bug in Safari where decimals are not
            // allowed in number inputs
            inputProps.step = "any";
        } */

        // If multipleOf is defined, use this as the step value. This mainly improves
        // the experience for keyboard users (who can use the up/down KB arrows).
        if (schema.multipleOf) {
            inputProps.step = schema.multipleOf;
        }

        if (typeof schema.minimum !== "undefined") {
            inputProps.min = schema.minimum;
        }

        if (typeof schema.maximum !== "undefined") {
            inputProps.max = schema.maximum;
        }

        return inputProps;
    }

    render() {
        let {
            id,
            value,
            readonly,
            disabled,
            autofocus,
            onBlur,
            onFocus,
            options,
            schema,
            required,
            isLoading,
            rawErrors,
            ...inputProps
        } = this.props;

        delete inputProps.formContext;
        delete inputProps.registry;
        delete inputProps.onChange;
        delete inputProps.onChangeEvent;

        if (isLoading) {
            return <IsLoadingInput {...this.props} />;
        }

        if (!id) {
            console.log("No id for", this.props);
            throw new Error(`no id for props ${JSON.stringify(this.props)}`);
        }

        inputProps = this.getInputProps(options, schema, inputProps);
        const type = inputProps.type;
        delete inputProps.type;

        if (type === "number" && typeof value === 'string') {
            value = Number(value.replace(",", "."));
        }

        const { title } = schema;

        const hasError = rawErrors && rawErrors.length > 0;
        const helperText = hasError ? rawErrors[0] : null;

        return (
            <TextField value={value == null ? "" : value}
                id={id}
                label={title}
                type={type}
                fullWidth

                {...inputProps}
                autoFocus={autofocus}
                disabled={disabled || readonly}
                required={required}
                helperText={helperText}
                error={hasError}

                onChange={this.handleChange.bind(this)}
                onFocus={onFocus && (event => onFocus(inputProps.id, event.target.value, event))}
                onBlur={onBlur && (event => onBlur(inputProps.id, event.target.value, event))}
            />
        );
    }
}

BaseInput.defaultProps = {
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
};

BaseInput.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    isLoading: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeEvent: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    rawErrors: PropTypes.array
};

export default BaseInput;