import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/styles';

import MailPreview from './preview/';
import MailContent from './content/';

import ApiRequest from '../../utils/apiRequest';

const styles = () => ({
    container: {
        display: 'flex',
        overflow: 'hidden'
    }
});

class MailClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previews: null,
            unreadCount: null,
            selectedMessageId: null
        };

        this.previewsRequest = null;
        this.countUnreadRequest = null;
    }

    componentDidMount() {
        this.initMail();
        this.countUnreadMails();
        this.queryMailsPreview();

        setInterval(() => {
            this.countUnreadRequest && this.countUnreadMails();
        }, 1000 * 60 * 3);
    }

    initMail = () => {
        const { systemServices: { inbox: { preview, countUnread } } } = this.props;

        this.previewsRequest = new ApiRequest(preview);
        this.countUnreadRequest = new ApiRequest(countUnread);
    }

    queryMailsPreview = () => {
        this.previewsRequest.send()
            .then(this.handleQueryMailsPreviewResponse);
    }

    handleQueryMailsPreviewResponse = previews => {
        if (_.isEqual(previews, this.state.previews)) {
            return;
        }
        this.setState({ previews });
    }

    countUnreadMails = () => {
        const { onCountUnredRequestStart } = this.props;

        onCountUnredRequestStart && onCountUnredRequestStart();
        this.countUnreadRequestBusy = true;

        this.countUnreadRequest.send()
            .then(this.handleCountUnreadResponse)
            .catch(this.handleCountUnreadError);
    }

    handleCountUnreadResponse = unreadCount => {
        const { onCountUnredRequestEnd } = this.props;

        onCountUnredRequestEnd && onCountUnredRequestEnd();
        this.countUnreadRequestBusy = false;

        if (unreadCount === this.state.unreadCount) {
            return;
        }
        this.queryMailsPreview();

        const { onNewMessages } = this.props;
        onNewMessages && onNewMessages(unreadCount);
    }

    handleCountUnreadError = () => {
        const { onCountUnredRequestEnd } = this.props;

        onCountUnredRequestEnd && onCountUnredRequestEnd();
        this.countUnreadRequestBusy = false;
    }

    handleRefreshClick = () => {
        if (this.previewsRequest && !this.countUnreadRequestBusy) {
            this.countUnreadMails();
            this.queryMailsPreview();
        }
    }

    handleMessageSelected = selectedMessageId => {
        this.setState({ selectedMessageId });
    }

    render() {
        const { previews, selectedMessageId } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <MailPreview
                    onRefreshClick={this.handleRefreshClick}
                    messages={previews}
                    onMessageSelected={this.handleMessageSelected}
                />
                <MailContent
                    selectedMessageId={selectedMessageId}
                />
            </div>
        );
    }
}

MailClient.contextTypes = {
    config: PropTypes.object.isRequired
};

MailClient.propTypes = {
    classes: PropTypes.object.isRequired,
    systemServices: PropTypes.shape({
        inbox: PropTypes.shape({
            preview: PropTypes.object,
            countUnread: PropTypes.object
        })
    }).isRequired,
    onNewMessages: PropTypes.func,
    onCountUnredRequestStart: PropTypes.func,
    onCountUnredRequestEnd: PropTypes.func
};

export default withStyles(styles)(MailClient);