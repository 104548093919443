import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const SecondaryListItemContent = props => {
    const { secondary, subTitle } = props;

    if (!secondary && !subTitle) {
        return null;
    }

    const renderSecondary = Boolean(secondary);
    const renderSubTitle = Boolean(subTitle);

    return (
        <Fragment>
            {renderSecondary &&
                <Typography variant="body2" color="textPrimary">
                    {secondary}
                </Typography>}
            {renderSubTitle && subTitle}
        </Fragment>
    );
};

SecondaryListItemContent.propTypes = {
    secondary: PropTypes.string,
    subTitle: PropTypes.string
};

export default SecondaryListItemContent;