import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MUIDrawer from '@material-ui/core/Drawer';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import List from './list/';

class Drawer extends Component {
    constructor() {
        super();

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.props.getDrawerRef(this.ref);
    }

    render() {
        let { classes, theme, open, onDrawerClose, onDrawerOpen, navigationConfig } = this.props;

        const DrawerClassNames = classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
        });
        const DrawerClasses = {
            paper: classNames({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            }),
        };

        return (
            <MUIDrawer
                variant="permanent"
                className={DrawerClassNames}
                classes={DrawerClasses}
                open={open}>

                <div ref={this.ref} className={classes.toolbar}>
                    <IconButton onClick={onDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List navigationConfig={navigationConfig}
                    drawerOpen={open} 
                    onDrawerOpen={onDrawerOpen}
                    onDrawerClose={onDrawerClose} />
            </MUIDrawer>
        );
    }
}

Drawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    onDrawerClose: PropTypes.func.isRequired,
    onDrawerOpen: PropTypes.func.isRequired,
    getDrawerRef: PropTypes.func.isRequired,
    open: PropTypes.bool,
    navigationConfig: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Drawer);