import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Base from './base';
import Confirm from './confirm';
import Form from './form';

const DialogRegister = {
    base: Base,
    confirm: Confirm,
    form: Form
};

class Dialog extends Component {
    render() {
        const { type, ...others } = this.props;

        const DialogComp = DialogRegister[type.toLowerCase()] || Base;

        return (
            <DialogComp {...others} />
        );
    }
}

Dialog.defaultProps = {
    type: "base"
};

Dialog.propTypes = {
    type: PropTypes.string.isRequired
};

export default Dialog;