import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _ from 'lodash';

import FullCalendar from '@fullcalendar/react';
import allLocales from '@fullcalendar/core/locales-all';

import ViewSelect, { Views } from './ui/viewSelect';

const Plugins = Views.reduce((acc, curr) => {
    if (curr.plugin) {
        acc.push(curr.plugin);
    }
    return acc;
}, []);

class Calendar extends Component {
    constructor() {
        super();

        this.state = {
            selectedDate: Moment(),
            events: null,
            originalEvents: null
        };

        this.calendarRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (_.isEqual(props.events, state.originalEvents)) {
            return null;
        }
        const events = props.events.map(({ start, ...others }) => {
            return {
                start: Moment.utc(start).format(),
                ...others
            };
        });

        return { events, originalEvents: props.events };
    }

    get calendarApi() {
        if (!this.calendarRef.current) {
            return null;
        }

        return this.calendarRef.current.calendar;
    }

    render() {
        const { user } = this.context;
        const { events } = this.state;
        const { refreshEvents } = this.props;

        return (
            <Fragment>
                <ViewSelect
                    calendarApi={this.calendarApi}
                    refreshEvents={refreshEvents}
                />
                <div style={{ flex: 1 }}>
                    <FullCalendar
                        ref={this.calendarRef}
                        defaultView="dayGridMonth"
                        header={false}
                        height="parent"
                        plugins={Plugins}
                        locales={allLocales}
                        locale={user.l10n}
                        events={events}
                    />
                </div>
            </Fragment>
        );
    }
}

Calendar.contextTypes = {
    user: PropTypes.object.isRequired
};

Calendar.propTypes = {
    events: PropTypes.array,
    refreshEvents: PropTypes.func.isRequired
};

export default Calendar;