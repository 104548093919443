import _ from 'lodash';

const FormatOrderBy = orderBy => {
    const formated = orderBy.map(order => {
        if (!Array.isArray(order)) {
            return [order, "ASC"];
        }
        return order;
    });

    return _.uniqBy(formated, item => item[0]);
};

const RetriveOrderOptionByOrder = (orderOptions, order) => {

    for (let i = 0; i < orderOptions.length; i++) {
        const orderOption = orderOptions[i];
        if (orderOption.value === order[0]) {

            return {
                ...orderOption,
                desc: order[1].toUpperCase() === "DESC",
                active: true
            };
        }
    }

    return null;
};

const CheckOrder = (orderOptions, orderBy) => {
    const newOrderOptions = [];

    orderBy.forEach(order => {
        const orderOption = RetriveOrderOptionByOrder(orderOptions, order);
        if (orderOption) {
            newOrderOptions.push(orderOption);
        }
    });

    orderOptions.forEach(orderOption => {
        const allreadyInNewOrderOptions = newOrderOptions
            .reduce((acc, curr) => acc || curr.id === orderOption.id, false);
        if (!allreadyInNewOrderOptions) {
            newOrderOptions.push(orderOption);
        }
    });

    return newOrderOptions;
};

export default (orderOptions, filterData) => {
    if (!filterData?.options?.orderBy) {
        return orderOptions;
    }

    const { orderBy } = filterData.options;

    const formatedOrderBy = FormatOrderBy(orderBy);

    const newOrderPositions = CheckOrder(orderOptions, formatedOrderBy);
    return newOrderPositions;
};