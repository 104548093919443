import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

import ArrayItem from './item';
import asComponent from '../../../../hoc/asComponent';

const styles = theme => ({
    root: {
        padding: theme.spacing(0.5),
        width: '100%'
    },
    header: {
        display: 'flex'
    },
    addButton: {
        marginLeft: 'auto'
    }
});

class CustomArrayTemplate extends Component {

    renderArrayItem = element => {
        return <ArrayItem {...element} />;
    }

    render() {
        const { classes, title, items, canAdd, onAddClick } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant="h6">{title}</Typography>
                    {canAdd &&
                        <Button
                            variant="contained"
                            className={classes.addButton}
                            onClick={onAddClick}
                        >
                            <AddIcon />
                        </Button>
                    }
                </div>
                {items.map(this.renderArrayItem)}
            </div>
        );
    }
}

CustomArrayTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    items: PropTypes.array,
    canAdd: PropTypes.bool,
    onAddClick: PropTypes.func
};

export default asComponent(withStyles(styles)(CustomArrayTemplate));