import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker as MuiDatePicker } from "@material-ui/pickers";

import withSimilarProps from './similarProps';

class DatePicker extends React.Component {
    render() {
        const { options, ...others } = this.props;
        const { format = "L" } = options;

        return (
            <MuiDatePicker
                {...others}
                options={options}
                format={format}
            />
        );
    }
}

DatePicker.propTypes = {
    options: PropTypes.object.isRequired
};

export default withSimilarProps(DatePicker);