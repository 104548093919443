import Blue from './blue';
import DarkBlue from './darkBlue';
import DarkGreen from './darkGreen';
import LightGreen from './lightGreen';

export default {
    Blue,
    DarkBlue,
    DarkGreen,
    LightGreen
};