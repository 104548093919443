import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DescriptionField from 'react-jsonschema-form/lib/components/fields/DescriptionField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class CheckboxWidget extends Component {

    handleChange({ target: { checked } }) {
        const { onChange } = this.props;
        onChange(checked);
    }

    schemaRequiresTrueValue(schema) {
        // Check if const is a truthy value
        if (schema.const) {
            return true;
        }

        // Check if an enum has a single value of true
        if (schema.enum && schema.enum.length === 1 && schema.enum[0] === true) {
            return true;
        }

        // If anyOf has a single value, evaluate the subschema
        if (schema.anyOf && schema.anyOf.length === 1) {
            return this.schemaRequiresTrueValue(schema.anyOf[0]);
        }

        // If oneOf has a single value, evaluate the subschema
        if (schema.oneOf && schema.oneOf.length === 1) {
            return this.schemaRequiresTrueValue(schema.oneOf[0]);
        }

        // Evaluate each subschema in allOf, to see if one of them requires a true
        // value
        if (schema.allOf) {
            return schema.allOf.some(this.schemaRequiresTrueValue);
        }
    }

    render() {
        const {
            schema,
            id,
            value,
            disabled,
            readonly,
            label,
            autofocus,
            onBlur,
            onFocus,
        } = this.props;

        const required = this.schemaRequiresTrueValue(schema);

        const control = <Switch checked={typeof value === "undefined" ? false : value}
            color="primary"
            onChange={this.handleChange.bind(this)}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            inputProps={{ required }}
            id={id}
            onBlur={onBlur && (event => onBlur(id, event.target.checked))}
            onFocus={onFocus && (event => onFocus(id, event.target.checked))} />;

        return (
            <div className={`checkbox ${disabled || readonly ? "disabled" : ""}`}>
                {schema.description && (
                    <DescriptionField description={schema.description} />
                )}
                <FormControlLabel
                    control={control}
                    label={label}
                />
            </div>
        );
    }
}

CheckboxWidget.defaultProps = {
    autofocus: false,
};

CheckboxWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.bool,
    //required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default CheckboxWidget;