import AsyncSelectWidget from './asyncSelectWidget/';
import BaseInput from './baseInput';
import CheckboxWidget from './checkboxWidget';
import DateWidget from './dateTimePicker/date';
import DateTimeWidget from './dateTimePicker/dateTime';
import SelectWidget from './selectWidget';
import TextareaWidget from './textareaWidget';

const Widgets = {
    "async-select": AsyncSelectWidget,
    BaseInput,
    CheckboxWidget,
    date: DateWidget,
    "date-time": DateTimeWidget,
    SelectWidget,
    TextareaWidget
};

export default Widgets;