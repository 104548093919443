import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Login from '../login/';
import AppBar from '../header/appBar';
import Drawer from './drawer';
import Main from '../main/';

class ContentContainer extends React.Component {
    constructor() {
        super();

        this.state = {
            drawerOpen: /* localStorage.getItem('navOpen') === 'true' ? true :  */false,
            collapseIn: false
        };
    }

    get drawerWidth() {
        if (this.drawerRef) {
            return this.drawerRef.current.clientWidth;
        }
        return 0;
    }

    handleDrawerOpen() {
        const { drawerOpen } = this.state;
        if (drawerOpen) return;
        localStorage.setItem('navOpen', 'true');
        this.setState({ drawerOpen: true });
    }

    handleDrawerClose() {
        const { drawerOpen } = this.state;
        if (!drawerOpen) return;
        localStorage.setItem('navOpen', 'false');
        this.setState({ drawerOpen: false });
    }

    render() {
        const { drawerOpen } = this.state;
        const { classes, navigationConfig, loggedIn, onLogin, onLogout, onUserUpdate, onUserPasswordChange } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar open={drawerOpen}
                    loggedIn={loggedIn}
                    onLogout={onLogout}
                    onUserUpdate={onUserUpdate}
                    onUserPasswordChange={onUserPasswordChange}
                    handleDrawerOpen={this.handleDrawerOpen.bind(this)} />
                <Drawer open={drawerOpen}
                    getDrawerRef={ref => this.drawerRef = ref}
                    onDrawerOpen={this.handleDrawerOpen.bind(this)}
                    onDrawerClose={this.handleDrawerClose.bind(this)}
                    navigationConfig={navigationConfig} />
                {loggedIn ?
                    <Main navigationConfig={navigationConfig} shiftContent={drawerOpen} />
                    : <Login onLogin={onLogin} />}
            </div>
        );
    }
}

ContentContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    navigationConfig: PropTypes.array,
    loggedIn: PropTypes.bool,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func,
    onUserUpdate: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(ContentContainer);