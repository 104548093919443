export default function RemoveNullAndUndefinedFromFormData(formData) {

    if (Array.isArray(formData)) {
        return formData.reduce((acc, curr) => {
            if (curr !== null && curr !== undefined) {
                const item = RemoveNullAndUndefinedFromFormData(curr);
                acc.push(item);
            }
            return acc;
        }, []);
    }

    if (typeof formData === 'object') {
        return Object.keys(formData)
            .reduce((acc, curr) => {
                const item = formData[curr];
                if (item !== null && item !== undefined) {
                    acc[curr] = RemoveNullAndUndefinedFromFormData(item);
                }
                return acc;
            }, {});
    }

    return formData;
}