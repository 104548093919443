import React from 'react';
import PropTypes from 'prop-types';

import { DateTimePicker as MuiDateTimePicker } from "@material-ui/pickers";

import withSimilarProps from './similarProps';

class DateTimePicker extends React.Component {
    render() {
        const { options, ...others } = this.props;
        const { format = "L LT" } = options;

        return (
            <MuiDateTimePicker
                {...others}
                options={options}
                format={format}
                ampm={false}
            />
        );
    }
}

DateTimePicker.propTypes = {
    options: PropTypes.object.isRequired
};

export default withSimilarProps(DateTimePicker);