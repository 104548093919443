import React from 'react';
import PropTypes from 'react-proptypes';

import Grid from '@material-ui/core/Grid';

function CustomObjectTemplate(props) {
    const { properties, classNames, uiSchema, description, DescriptionField, title, TitleField } = props;

    if (uiSchema['ui:widget'] === "hidden") return null;

    return (
        <Grid item container xs={12} className={classNames} spacing={4} >
            {(title || description) &&
                <Grid item xs={12}>
                    {title && title !== "" && <TitleField title={title} />}
                    <DescriptionField description={description} />
                </Grid>}
            <Grid item container xs={12} spacing={4}>
                {properties.map((element) => {
                    return element.content;
                })}
            </Grid>
        </Grid>
    );
}

CustomObjectTemplate.propTypes = {
    title: PropTypes.string,
    TitleField: PropTypes.func,
    DescriptionField: PropTypes.func,
    properties: PropTypes.array,
    classNames: PropTypes.string,
    description: PropTypes.string,
    uiSchema: PropTypes.object
};

export default CustomObjectTemplate;