import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AccountCircle from '@material-ui/icons/AccountCircle';

import IconButton from '@material-ui/core/IconButton';

import Popper from './popper';

class User extends Component {
    constructor() {
        super();

        this.state = {
            anchorEl: null
        };
    }

    static getDerivedStateFromProps(props) {
        if (!props.loggedIn) {
            return { anchorEl: null };
        }

        return null;
    }

    handleClick = (evt) => {
        this.setState({ anchorEl: evt.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    render() {
        const { onLogout, onUserUpdate, onUserPasswordChange } = this.props;
        const { user } = this.context;
        const { anchorEl } = this.state;

        if (!user) {
            return null;
        }

        return (
            <div>
                <IconButton color="inherit" onClick={this.handleClick}>
                    <AccountCircle />
                </IconButton>
                <Popper
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    onLogout={onLogout}
                    onUserUpdate={onUserUpdate}
                    onUserPasswordChange={onUserPasswordChange}
                />
            </div>
        );
    }
}

User.contextTypes = {
    user: PropTypes.object
};

User.propTypes = {
    loggedIn: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default User;