import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from "react-beautiful-dnd";

import Portal from '@material-ui/core/Portal';
import List from '@material-ui/core/List';

import OrderOption from '../orderOption';

const DragItem = props => {
    const { id, index, ...others } = props;

    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                snapshot.isDragging ?
                    <Portal>
                        <List>
                            <OrderOption
                                index={index}
                                id={id}
                                key={"draggable_for_" + id}
                                provided={provided}
                                snapshot={snapshot}
                                {...others}
                            />
                        </List>
                    </Portal>
                    :
                    <OrderOption
                        index={index}
                        id={id}
                        key={"draggable_for_" + id}
                        provided={provided}
                        snapshot={snapshot}
                        {...others}
                    />
            )}
        </Draggable>
    );
};

DragItem.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number
};

export default DragItem;