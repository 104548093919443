import _ from 'lodash';

import ThemeRegister from '../styles/themes/';

const DefaultThemeName = "Blue";
const Local_Storage_Theme_Key = "manager_current_mui_theme";

export default class StyleHandler {
    constructor() {
        this.availableThemes = _.keys(ThemeRegister);

        this.events = {
            ThemeChange: () => { }
        };
    }

    get currentTheme() {
        if (this.currentThemeName) {
            if (this.availableThemes.indexOf(this.currentThemeName) !== -1) {
                const theme = ThemeRegister[this.currentThemeName];
                return theme;
            }
        }

        return ThemeRegister[DefaultThemeName]
    }

    set currentThemeName(themeName) {
        localStorage.setItem(Local_Storage_Theme_Key, themeName);
    }

    get currentThemeName() {
        return localStorage.getItem(Local_Storage_Theme_Key);
    }

    on = (eventName, cb) => {
        this.events[eventName] = cb;
    }

    changeTheme = themeName => {
        if (this.availableThemes.indexOf(themeName) === -1) {
            themeName = DefaultThemeName;
        }
        if (themeName === this.currentThemeName) {
            return;
        }
        const newTheme = ThemeRegister[themeName];
        this.currentThemeName = themeName;
        this.events.ThemeChange(newTheme);
    }
}