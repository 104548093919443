import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mitt from 'mitt';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import FilterToolbar from '../filterToolbar/';
import CompponentRegister from './componentRegister';
import ComponentNotFound from './componentNotFound';

import ApiRequest from '../../utils/apiRequest';
import EventHandlerRegister from '../../utils/eventHandlerRegister';

class ComponentWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            componentConfig: null
        };

        this.eventHandler = mitt();
        this.eventHandler.on('*', (type, data) => console.trace('emitted event: ' + type + ' on component: ' + props.componentName + ' - ', data));

        this.eventHandlerRegister = new EventHandlerRegister();
        this.eventHandlerRegister.addComponentHandler(props.componentName, this.eventHandler);
    }

    componentDidMount() {
        this.loadComponentConfig.call(this);
    }

    /* ##################### ComponentConfig ##################### */

    loadComponentConfig() {
        const { componentName } = this.props;
        const { config: { systemServices: { component } } } = this.context;

        let req = new ApiRequest(component);
        req.uri += componentName;
        req.send()
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    handleResponse(componentConfig) {
        const { componentName } = this.props;

        componentConfig.eventHandler = componentName;

        if (componentConfig.service && !componentConfig.service.eventHandler) {
            componentConfig.service.eventHandler = componentName;
        }

        this.setState({
            isLoading: false,
            componentConfig
        });
    }

    handleError() {
        this.setState({ isLoading: false });
    }

    getFilterToolbarContent() {
        const { componentName, filter } = this.props;
        const { componentConfig, isLoading } = this.state;

        if (!isLoading && componentConfig && componentConfig.filterToolbar) {

            return <FilterToolbar {...componentConfig.filterToolbar}
                filterData={filter}
                eventHandlerName={componentName} />;
        }
        return null;
    }

    getContent() {
        const { componentConfig, isLoading } = this.state;

        if (isLoading) {
            return <LinearProgress />;
        }
        if (componentConfig) {
            const { filter } = this.props;
            const Comp = CompponentRegister[componentConfig.type] || ComponentNotFound;

            return <Comp componentConfig={componentConfig}
                filterData={filter} />;
        }
        return null;
    }

    render() {
        const { classes } = this.props;

        const filterToolbarContent = this.getFilterToolbarContent.call(this);
        const content = this.getContent.call(this);

        return (
            <Paper className={classes.componentWrapper}>
                {filterToolbarContent}
                {content}
            </Paper>
        );
    }
}

ComponentWrapper.contextTypes = {
    config: PropTypes.object.isRequired
};

ComponentWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    componentName: PropTypes.string,
    filter: PropTypes.object
};

export default withStyles(styles)(ComponentWrapper);