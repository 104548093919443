import React from 'react';
import PropTypes from 'react-proptypes';

import Grid from '@material-ui/core/Grid';

function CustomFieldTemplate(props) {
    const { classNames, help, description, children, schema, uiSchema } = props;
    let size = uiSchema.size || { xs: 12 };

    if (uiSchema['ui:widget'] === "hidden") return null;
    let fieldDescription = schema.type !== "object" ? description : null;
    return (
        <Grid item container className={classNames} {...size} >
            {fieldDescription}
            {children}
            {help}
        </Grid>
    );
}

CustomFieldTemplate.propTypes = {
    id: PropTypes.string,
    classNames: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.object,
    required: PropTypes.bool,
    description: PropTypes.object,
    errors: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    schema: PropTypes.object,
    uiSchema: PropTypes.object
};

export default CustomFieldTemplate;