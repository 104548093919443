import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";

import { withStyles } from '@material-ui/core/styles/';


import Sort from './sort/';
import Form from '../form/base';
import EventHandlerRegister from '../../utils/eventHandlerRegister';

const styles = () => ({
    filterToolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end',

        "& .rjsf": {
            width: '100%'
        }
    }
});

class FilterToolbar extends Component {
    constructor(props) {
        super(props);

        const eventHandlerRegister = new EventHandlerRegister();
        this.eventHandler = eventHandlerRegister.getComponentHandler(props.eventHandlerName);

        this.formRef = null;
        this.timeOut = null;
    }

    componentDidMount() {
        if (this.formRef) {
            this.formRef.submit();
            return;
        }
    }

    handleChange() {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
            this.formRef.submit();
        }, 100);
    }

    handleSubmit = ({ formData }) => {
        this.setFilterData(formData);
    }

    setFilterData = filterData => {
        const { history, location } = this.props;

        this.eventHandler.emit("filter changed", filterData);

        const filter = "?filter=" + JSON.stringify(filterData);
        history.push({
            pathname: location.pathname,
            search: filter
        });
    }

    render() {
        const { classes, formProps, filterData, sortProps } = this.props;

        const hasSort = Boolean(sortProps);
        const hasFilter = Boolean(formProps);

        return (
            <div className={classes.filterToolbar}>
                {hasFilter && <Form {...formProps}
                    formData={filterData}
                    formRef={ref => this.formRef = ref}
                    onChange={this.handleChange.bind(this)}
                    onSubmit={this.handleSubmit} />}
                {hasSort && <Sort filterData={filterData} onSubmit={this.setFilterData} {...sortProps} />}
            </div>
        );
    }
}

FilterToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    eventHandlerName: PropTypes.string.isRequired,
    filterData: PropTypes.object,
    formProps: PropTypes.shape({
        schema: PropTypes.object.isRequired
    }),
    sortProps: PropTypes.object
};

export default withStyles(styles)(withRouter(FilterToolbar));