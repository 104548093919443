import ST from 'stjs';

class OldRequest {
    constructor(_serverURL, _user) {
        this.serverURL = _serverURL;
        this.user = _user;

        this.upload = this.upload.bind(this);
        this.getFormJsonSchemaByName = this.getFormJsonSchemaByName.bind(this);
        this.sendByService = this.sendByService.bind(this);
        this.send = this.send.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.responseToJson = this.responseToJson.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    upload(file) {
        let url = "/upload";
        /* let req = {
            filter: {},
            auth: user,
            File: file
        }; */
        var formData = new FormData();
        formData.append('file', file);
        formData.append('auth', JSON.stringify(this.user));

        let requestData = {
            method: "POST",
            credentials: 'same-origin',
            body: formData
        };
        url = this.serverURL + url.toLowerCase() + ".php";
        return this.sendRequest(url, requestData);
    }

    getFormJsonSchemaByName(name) {
        let url = "/config/form";
        let req = {
            name: name.toLowerCase(),
            auth: this.user
        };
        return this.send(url, "read", req);
    }

    /* 
     *   "service": {
     *       "url": "/Sales/ContractHead",
     *       "keys": [
     *           "contractNumber"
     *       ],
     *       "dataName": "ContractHead",
     *       "view": "ContractHead"
     *   }
     */

    sendByService(service, data) {
        let req = {
            [service.dataName]: data,
            view: service.view || null,
            auth: this.user
        };
        service.keys && service.keys.forEach(key => {
            req[key] = data[key];
        });
        if (service.filter) {
            req.filter = service.filter;
        }
        return this.send(service.url, service.method, req, service.onResponse);
    }

    send(url, method, req = {}, onResponse = {}) {
        /* if (Config === {}) {
            let configLoader = new Loader();
            configLoader.loadConfig(() => this.send(url, method, req))
                .then((res) => {
                    Config = res.json();
                })
                .catch(err => console.log(err));
        } */

        req = Object.assign({
            filter: {},
            data: {},
            view: null,
            auth: this.user
        }, req);
        let requestData = {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: "data=" + encodeURIComponent(JSON.stringify(req))
        };
        url = this.serverURL + url.toLowerCase() + ".php?method=" + method;
        return this.sendRequest(url, requestData, onResponse);
    }

    sendRequest(url, data, onResponse) {
        return fetch(url, data)
            .then(this.responseToJson)
            .then(res => this.handleResponse(res, onResponse));
    }

    responseToJson(response) {
        return response = response.json();
    }

    handleResponse(response, onResponse) {
        if (response.hasOwnProperty('basketToken')) {
            localStorage.setItem('basketToken', response.basketToken);
            delete response.basketToken;
        }

        if (!response.ok) {
            console.error(response.msg);
        }

        if (onResponse) {
            if (onResponse.transform) {
                let data = {
                    res: response.data
                };
                response.data = ST.select(data)
                    .transformWith(onResponse.transform)
                    .root();
            }
        }

        return response.data;
    }
}

export default OldRequest;