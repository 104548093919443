import React, { Component } from 'react';

import BaseInput from './baseInput';

class TextArea extends Component {
    render() {
        return (
            <BaseInput
                multiline
                {...this.props}
            />
        );
    }
}

export default TextArea;