import React, { Component } from 'react';
import defaultsDeep from 'lodash/defaultsDeep';

import FormContainer from '../../form/formContainer';

function withForm(ParentComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                formConfig: null
            };
        }

        openForm(formConfig) {
            const { componentConfig } = this.props;

            if (!formConfig.name) {
                formConfig.name = componentConfig.form;
            }

            if (formConfig.service) {
                formConfig.service = defaultsDeep(formConfig.service, componentConfig.service);
            }

            if (formConfig.onSubmit.service) {
                formConfig.onSubmit.service = defaultsDeep(formConfig.onSubmit.service, componentConfig.service);
            }

            //formConfig.onFormSubmitted = result => componentConfig.eventHandler.emit('formSubmitted', { result });

            this.setState({ formConfig });
        }

        closeForm() {
            this.setState({ formConfig: null });
        }

        render() {
            const { formConfig } = this.state;
            const { filterData, ...others } = this.props;

            return [
                <ParentComponent {...others} key="parent-component-with-form"
                    openForm={this.openForm.bind(this)}
                    closeForm={this.closeForm.bind(this)} />,
                <FormContainer key="form-container"
                    formProps={formConfig}
                    filterData={filterData}
                    onClose={this.closeForm.bind(this)} />
            ];
        }
    };
}

export default withForm;