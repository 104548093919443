import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import Glyph from '../glyph';

class FloatingActionButtons extends Component {

    renderFab(buttonConfig, index) {
        const { classes, onButtonClick, className } = this.props;

        const {
            onClick,
            size = "small",
            color = "secondary",
            label = "",
            glyph
        } = buttonConfig;

        const fabClassName = cx(classes.fab, className);

        return <Fab key={index} onClick={() => onButtonClick(onClick)}
            size={size}
            color={color}
            aria-label={label}
            className={fabClassName}>
            {glyph && <Glyph glyph={glyph} />} {label}
        </Fab>;
    }

    render() {
        let { buttonConfig } = this.props;

        return buttonConfig.map(this.renderFab, this);
    }
}

FloatingActionButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    buttonConfig: PropTypes.array.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default withStyles(styles)(FloatingActionButtons);