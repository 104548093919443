import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _ from 'lodash';

import { withStyles } from '@material-ui/styles';
import { Calendar as MuiCalendar } from '@material-ui/pickers';
import Badge from '@material-ui/core/Badge';

import MobileDetail from './mobileDetail';

const styles = () => ({
    badge: {
        bottom: -2,
        top: 'unset',
        right: 'unset',
        left: 'calc(50% - 5.5px)'
    }
});

const CreateEventRegister = events => {
    return events.reduce((acc, curr) => {
        const date = Moment.utc(curr.start).local().format("YYYY-MM-DD");
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(curr);
        acc[date].sort((a, b) => {
            const firstDate = Moment(a.start);
            const secondDate = Moment(b.start);

            if (firstDate.isBefore(secondDate)) {
                return -1;
            }
            if (firstDate.isAfter(secondDate)) {
                return 1;
            }

            return 0;
        });
        return acc;
    }, {});
};

class Calendar extends Component {
    constructor() {
        super();

        this.state = {
            selectedDate: Moment(),
            events: {},
            originalEvents: null
        };
    }

    componentDidMount() {
        this.refreshEvents();
    }

    static getDerivedStateFromProps(props, state) {
        if (_.isEqual(props.events, state.originalEvents)) {
            return null;
        }
        const events = CreateEventRegister(props.events);
        return { events, originalEvents: props.events };
    }

    refreshEvents = selectedDate => {
        const { refreshEvents } = this.props;

        return refreshEvents(selectedDate || this.state.selectedDate, "months");
    }

    handleChange = selectedDate => {
        this.setState({ selectedDate });
    }

    handleMonthChange = selectedDate => {
        this.setState({ selectedDate });
        return this.refreshEvents()
            .catch(err => console.log(err));
    }

    renderDay = (day, selectedDate, dayInCurrentMonth, DayComponent) => {
        const { classes } = this.props;
        const { events } = this.state;

        const hasEvent = Boolean(events[day.format("YYYY-MM-DD")]);
        const visible = dayInCurrentMonth && hasEvent;

        return (
            <Badge color="secondary" variant="dot" invisible={!visible} classes={{ badge: classes.badge }} overlap="circle">
                {DayComponent}
            </Badge>
        );
    }

    render() {
        const { selectedDate, events } = this.state;

        const selectedEvents = events[selectedDate.format("YYYY-MM-DD")];

        return (
            <Fragment>
                <MuiCalendar
                    date={selectedDate}
                    onChange={this.handleChange}
                    onMonthChange={this.handleMonthChange}
                    renderDay={this.renderDay}
                />
                <MobileDetail selectedEvents={selectedEvents} />
            </Fragment>
        );
    }
}

Calendar.propTypes = {
    classes: PropTypes.object.isRequired,
    events: PropTypes.array,
    refreshEvents: PropTypes.func.isRequired
};

export default withStyles(styles)(Calendar);