import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Base from './base';
import RemoveNullAndUndefinedFromFormData from './helpers/removeNullAndUndefinedFromFormData';
import ApiRequest from '../../utils/apiRequest';

const FormConfigCache = {};

class Form extends React.Component {
    constructor(props) {
        super(props);

        const formData = RemoveNullAndUndefinedFromFormData(props.formData);
        this.state = {
            schema: null,
            uiSchema: null,
            formData
        };
    }

    componentDidMount() {
        this.loadFormConfig.call(this);
    }

    loadFormConfig() {
        const { name } = this.props;
        const { alert, config: { systemServices: { form } } } = this.context;

        if (!name) {
            alert.error("no schema name provided");
        }

        if (FormConfigCache[name]) {
            this.handleResponse.call(this, FormConfigCache[name]);
            return;
        }

        const req = new ApiRequest(form);
        req.uri += name;
        req.send()
            .then(res => FormConfigCache[name] = res)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    handleResponse({ schema, uiSchema }) {
        this.setState({ schema, uiSchema });
    }

    handleError(err) {
        const { alert } = this.context;
        alert.error(err.message);
    }

    handleSubmit(data) {
        let { onClose, onSubmit } = this.props;

        if (typeof onSubmit === 'function') {
            onClose();
            onSubmit(data);
            return;
        }

        if (onSubmit.service) {
            let req = new ApiRequest(onSubmit.service);
            return req.send(data.formData)
                .then(this.handleSubmitResponse.bind(this, data))
                .catch((err) => {
                    console.error(err);
                    this.context.alert.error(err.message);
                });
        }
    }

    handleSubmitResponse(submittedFormData, response) {
        let { onClose, onSubmit, onFormSubmitted } = this.props;
        const { alert } = this.context;

        onClose();
        onFormSubmitted && onFormSubmitted(response, submittedFormData);

        if (response && onSubmit.successMessage) {
            alert.success(onSubmit.successMessage);
        }
        /* 
        !onSubmit.keepFormData && this.setState({ formData: null });
        if (response && onSubmit.postFb) {
            let fb = new Facebook(this.context.FB, this.context.config);
            fb.postImages({
                images: data.formData[onSubmit.postFb.imgName],
                data: data.formData[onSubmit.postFb.dataName]
            });
        } */
    }

    render() {
        const { schema, uiSchema, formData } = this.state;
        if (!schema) return null;
        const { classes, onClose, filterData, formRef } = this.props;

        const FormProps = {
            onSubmit: this.handleSubmit.bind(this),
            //onChange: this.handleChange.bind(this),
            formRef,
            onClose,
            schema,
            uiSchema,
            formData,
            filterData
        };

        return (
            <div className={classes.form}>
                <Grid container className={classes.root}>
                    <Base {...FormProps} />
                </Grid>
            </div>
        );
    }
}

Form.contextTypes = {
    alert: PropTypes.object,
    config: PropTypes.object.isRequired
};

Form.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    formData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]),
    onFormSubmitted: PropTypes.func,
    formRef: PropTypes.func,
    filterData: PropTypes.object
};

export default withStyles(styles)(Form);