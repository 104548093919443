import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormDialog from '../../../dialog/form';

import ApiRequest from '../../../../utils/apiRequest';

const FormConfig = {
    "schema": {
        "type": "object",
        "properties": {
            "username": {
                "type": "string",
                "title": "Benutzername"
            },
            "theme": {
                "type": "string",
                "title": "Theme",
                "enum": [
                    "Blue",
                    "DarkBlue",
                    "LightGreen",
                    "DarkGreen"
                ],
                "enumNames": [
                    "Blau",
                    "Dunkel Blau",
                    "Hell Grün",
                    "Dunkel Grün"
                ]
            },
            "person": {
                "title": "",
                "type": "object",
                "properties": {
                    "form_of_address": {
                        "type": "string",
                        "title": "Anrede",
                        "enum": [
                            "Frau",
                            "Herr",
                            "Firma"
                        ]
                    },
                    "first_name": {
                        "type": "string",
                        "title": "Vorname"
                    },
                    "last_name": {
                        "type": "string",
                        "title": "Nachname"
                    },
                    "email": {
                        "type": "string",
                        "title": "Email"
                    },
                    "mobile": {
                        "type": "string",
                        "title": "Mobiltelefon"
                    }
                }
            }
        }
    },
    "uiSchema": {
        "ui:order": [
            "username",
            "theme",
            "person"
        ],
        "username": {
            "ui:disabled": true,
            "size": {
                "xs": 8
            }
        },
        "theme": {
            "ui:disabled": false,
            "size": {
                "xs": 4
            }
        },
        "person": {
            "size": {
                "sm": 12
            },
            "ui:order": [
                "form_of_address",
                "first_name",
                "last_name",
                "email",
                "mobile"
            ],
            "form_of_address": {
                "ui:disabled": false,
                "size": {
                    "sm": 2
                }
            },
            "first_name": {
                "ui:disabled": false,
                "size": {
                    "sm": 4
                }
            },
            "last_name": {
                "ui:disabled": false,
                "size": {
                    "sm": 6
                }
            },
            "email": {
                "size": {
                    "sm": 8
                }
            },
            "mobile": {
                "size": {
                    "sm": 4
                }
            }
        }
    }
};

class EditAccountDialog extends Component {
    constructor() {
        super();

        this.formRef = null;
    }

    handleSubmit = (data) => {
        const { config: { systemServices: { updateUser } } } = this.context;

        const request = new ApiRequest(updateUser);
        request.send(data.formData)
            .then(this.handleResponse);
    }

    handleResponse = () => {
        // comming soon
    }

    render() {
        const { onClose, dialogOpen, onUserUpdate } = this.props;

        const formData = this.context.user;

        return (
            <FormDialog
                fixedHeight={true}
                maxWidth="md"
                fullWidth
                title="Konto"
                onClose={onClose}
                onSubmit={onUserUpdate}
                FormProps={{ ...FormConfig, formData }}
                dialogOpen={dialogOpen}
            />
        );
    }
}

EditAccountDialog.contextTypes = {
    config: PropTypes.object.isRequired,
    user: PropTypes.object
};

EditAccountDialog.propTypes = {
    dialogOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onUserUpdate: PropTypes.func
};

export default EditAccountDialog;