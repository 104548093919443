import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import List from '@material-ui/core/List';

import DragItem from './dragItem';

const DragAndDropList = (props) => {
    const { items, onSubmit } = props;
    const [dragItems, setDragItems] = useState(items);

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const newDragItems = [...dragItems];
        newDragItems.move(source.index, destination.index);
        setDragItems(newDragItems);
        onSubmit(newDragItems);
    };

    const handleActiveToggle = (id, value) => {
        const newDragItems = [...dragItems];

        for (let i = 0; i < newDragItems.length; i++) {
            const newDragItem = newDragItems[i];

            if (newDragItem.id === id) {
                newDragItems[i].active = value;
                break;
            }
        }

        setDragItems(newDragItems);
        onSubmit(newDragItems);
    };

    const handleOrderDirectionToggle = (id, value) => {
        const newDragItems = [...dragItems];

        for (let i = 0; i < newDragItems.length; i++) {
            const newDragItem = newDragItems[i];

            if (newDragItem.id === id) {
                newDragItems[i].desc = value;
                break;
            }
        }

        setDragItems(newDragItems);
        onSubmit(newDragItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="filter-toolbar_sort">
                {(provided) => (
                    <List {...provided.droppableProps} innerRef={provided.innerRef}>
                        {dragItems.map((item, index) => <DragItem key={item.id} {...item} onActiveToggle={handleActiveToggle} onOrderDirectionToggle={handleOrderDirectionToggle} index={index} />)}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

DragAndDropList.propTypes = {
    items: PropTypes.array,
    onSubmit: PropTypes.func.isRequired
};

export default DragAndDropList;