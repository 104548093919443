//import BooleanField from './booleanField';
//import NumberField from './numberField';
//import StringField from './stringField';
//import SchemaField from './schemaField';
import TitleField from './titleField';

export default {
    //BooleanField,
    //NumberField,
    //StringField,
    //SchemaField,
    TitleField
};