import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';

import Dialog from '../../../dialog/';
import MailClient from '../../../mailClient/';

const styles = () => ({

});

class UserPopper extends Component {
    render() {
        const { classes, dialogOpen, onClose, ...others } = this.props;

        return (
            <Dialog
                className={classes.popper}
                open={dialogOpen}
                onClose={onClose}
                keepMounted
                fullWidth
                maxWidth={false}
            >
                <MailClient
                    {...others}
                />
            </Dialog>
        );
    }
}

UserPopper.propTypes = {
    classes: PropTypes.object.isRequired,
    dialogOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onNewMessages: PropTypes.func
};

export default withStyles(styles)(UserPopper);