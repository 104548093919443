import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import withComponent from '../main/hocs/withComponent';
import FloatingActionButtons from '../main/floatingActionButtons';

import EventHandlerRegister from '../../utils/eventHandlerRegister';

import VirtualList from './virtualList';
import ListItem from './item';
import ListFooter from './footer';

const styles = theme => ({
    listWrapper: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: 'hidden'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing()
    }
});

class List extends Component {
    constructor(props) {
        super(props);

        this.initialFilter = null;

        const { componentConfig: { eventHandler } } = props;

        const register = new EventHandlerRegister();
        this.componentEventHandler = register.getComponentHandler(eventHandler);

        this.componentEventHandler.on('filter changed', this.handleFilterChange.bind(this));
    }

    componentDidMount() {
        this._mounted = true;
        const { initComponentData } = this.props;

        this.componentEventHandler.on('resource added', this.handleResourceEvent.bind(this));
        this.componentEventHandler.on('resource edited', this.handleResourceEvent.bind(this));
        this.componentEventHandler.on('resource deleted', this.handleResourceEvent.bind(this));

        initComponentData(this.initialFilter);
    }

    componentWillUnmount() {
        this._mounted = false;

        this.componentEventHandler.off('resource added', this.handleResourceEvent.bind(this));
        this.componentEventHandler.off('resource edited', this.handleResourceEvent.bind(this));
        this.componentEventHandler.off('resource deleted', this.handleResourceEvent.bind(this));

        this.componentEventHandler.off('filter changed', this.handleFilterChange.bind(this));
    }

    handleResourceEvent() {
        const { refreshComponentData } = this.props;

        refreshComponentData();
    }

    handleFilterChange(data) {
        if (this._mounted) {
            const { setFilter } = this.props;

            setFilter(data);
            return;
        }

        this.initialFilter = data;
    }

    renderListItem(item, index) {
        const { componentConfig, clickButton } = this.props;

        let keys = index;
        if (componentConfig.service && componentConfig.service.keys) {
            keys = componentConfig.service.keys;
            if (!Array.isArray(keys)) {
                keys = [keys];
            }
            keys = keys.map(key => item[key]).join('-');
        }

        return <ListItem key={keys}
            item={item}
            componentConfig={componentConfig}
            onButtonClick={clickButton} />;
    }

    render() {
        const { classes, componentConfig, clickButton, componentData } = this.props;
        const { FabsConfig } = componentConfig.buttonSplitedConfig;

        if (!componentData) {
            return null;
        }

        const hasFooterTemplate = Boolean(componentConfig.footerDisplayTemplate);
        const fabClassName = cx({
            [classes.fab]: hasFooterTemplate
        });

        return (
            <div className={classes.listWrapper} >
                <Typography variant="caption">{componentConfig.title}</Typography>
                <VirtualList
                    items={componentData}
                    componentConfig={componentConfig}
                    onButtonClick={clickButton}
                />
                <ListFooter items={componentData} componentConfig={componentConfig} />
                <FloatingActionButtons buttonConfig={FabsConfig}
                    onButtonClick={clickButton}
                    className={fabClassName} />
            </div>
        );
    }
}

List.propTypes = {
    classes: PropTypes.object.isRequired,
    componentData: PropTypes.array,
    initComponentData: PropTypes.func.isRequired,
    refreshComponentData: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    clickButton: PropTypes.func.isRequired,
    componentConfig: PropTypes.shape({
        title: PropTypes.string,
        service: PropTypes.object,
        eventHandler: PropTypes.string,
        footerDisplayTemplate: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        buttonSplitedConfig: PropTypes.shape({
            FabsConfig: PropTypes.array
        })
    })
};

export default withStyles(styles)(withComponent(List));