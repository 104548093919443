import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from '../contentContainer/styles';

import MUIAppBar from '@material-ui/core/AppBar';

import Toolbar from './toolbar/';

class AppBar extends Component {

    render() {
        let { classes, handleDrawerOpen, open, loggedIn, onLogout, onUserUpdate, onUserPasswordChange } = this.props;

        const appBarClassName = classNames(classes.appBar, {
            [classes.appBarShift]: open,
        });

        return (
            <MUIAppBar position="fixed"
                elevation={0}
                className={appBarClassName}
            >
                <Toolbar
                    openDrawer={open}
                    loggedIn={loggedIn}
                    handleDrawerOpen={handleDrawerOpen}
                    onLogout={onLogout}
                    onUserUpdate={onUserUpdate}
                    onUserPasswordChange={onUserPasswordChange}
                />
            </MUIAppBar>
        );
    }
}

AppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    loggedIn: PropTypes.bool,
    handleDrawerOpen: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(AppBar);