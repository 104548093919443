import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MUIList from '@material-ui/core/List';
import ListItem from './listItem';

class List extends Component {
    render() {
        let { navigationConfig, drawerOpen, onDrawerOpen, onDrawerClose } = this.props;

        if (!navigationConfig) return null;

        return (
            <MUIList>
                {navigationConfig.map((item, index) => (
                    <ListItem key={index}
                        drawerOpen={drawerOpen}
                        onDrawerOpen={onDrawerOpen}
                        onDrawerClose={onDrawerClose}
                        item={item}
                    />
                ))}
            </MUIList>
        );
    }
}

List.propTypes = {
    navigationConfig: PropTypes.array,
    drawerOpen: PropTypes.bool,
    onDrawerOpen: PropTypes.func.isRequired,
    onDrawerClose: PropTypes.func.isRequired
};

export default List;