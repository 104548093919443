import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

import PopperContent from './popperContent';

const styles = theme => ({
    popper: {
        zIndex: theme.zIndex.modal
    }
});

class UserPopper extends Component {
    render() {
        const { classes, anchorEl, onClose, onLogout, onUserUpdate, onUserPasswordChange } = this.props;

        const popperOpen = Boolean(anchorEl);

        return (
            <Popper
                className={classes.popper}
                open={popperOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <PopperContent
                            onClose={onClose}
                            onLogout={onLogout}
                            onUserUpdate={onUserUpdate}
                            onUserPasswordChange={onUserPasswordChange}
                        />
                    </Fade>
                )}
            </Popper>
        );
    }
}

UserPopper.propTypes = {
    classes: PropTypes.object.isRequired,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    onLogout: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func,
    onUserPasswordChange: PropTypes.func
};

export default withStyles(styles)(UserPopper);