import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import BaseDialog from './base';
import Form from '../form/base';

class FormDialog extends Component {
    constructor() {
        super();

        this.formRef = null;
    }

    handleSubmit = (...args) => {
        const { onSubmit, onClose, suppressCloseOnSubmit } = this.props;

        if (onSubmit) {
            onSubmit(...args);

            const onCloseIsDefinedAndAllowed = onSubmit && onClose && !suppressCloseOnSubmit;
            if (onCloseIsDefinedAndAllowed) {
                onClose();
            }
        }
    }

    triggerSubmit = () => {
        this.formRef.submit();
    }

    render() {
        const { onClose, dialogOpen, title, FormProps, ...others } = this.props;
        delete others.onSubmit;
        delete others.suppressCloseOnSubmit;

        return (
            <BaseDialog onClose={onClose} aria-labelledby="form-dialog" open={dialogOpen} {...others}>
                <DialogTitle id="form-dialog">{title}</DialogTitle>
                <DialogContent>
                    <Form
                        {...FormProps}
                        formRef={ref => this.formRef = ref}
                        onSubmit={this.handleSubmit}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Abbrechen
                    </Button>
                    <Button onClick={this.triggerSubmit} color="primary" autoFocus>
                        Bestätigen
                    </Button>
                </DialogActions>
            </BaseDialog>
        );
    }
}

FormDialog.defaultProps = {
    suppressCloseOnSubmit: false
};

FormDialog.propTypes = {
    suppressCloseOnSubmit: PropTypes.bool,
    dialogOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    FormProps: PropTypes.object
};

export default FormDialog;