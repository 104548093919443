import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

class MailSender extends Component {
    render() {
        const { senders } = this.props;

        const name = senders[0].name;
        const email = senders[0].email;
        const showEmail = Boolean(name);

        return (
            <div>
                <Typography variant="h6">{name || email}</Typography>
                {showEmail && <Typography variant="caption">{email}</Typography>}
            </div>
        );
    }
}

MailSender.propTypes = {
    senders: PropTypes.array.isRequired
};

export default MailSender;