import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Route } from "react-router-dom";
import { withRouter } from "react-router";

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ComponentWrapper from './componentWrapper';

class Main extends Component {
    constructor(props) {
        super(props);

        this.mainRef = null;
    }/* 

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged.call(this);
        }
    }

    onRouteChanged() {
        const { top } = this.mainRef.getBoundingClientRect();

        try {
            window.scrollTo({ left: 0, top, behavior: "smooth" });
        } catch (err) {
            window.scrollTo(0, top);
        }
    } */

    /* evalFilterParams(filterParams) {
        const checkString = (str, startChar, endChar) => {
            return str.startsWith(startChar) && str.endsWith(endChar);
        };

        const reduceString = str => {
            return str.substring(1, str.length - 1);
        };

        const evalArray = (value) => {
            return value.split(',')
                .reduce((acc, curr) => {
                    const value = getValueByDataType(curr);
                    acc.push(value);
                    return acc;
                }, []);
        };

        const getValueByDataType = (value) => {

            if (value === "true" || value === "false") {
                return Boolean(value);
            }
            if (value === "null") {
                return null;
            }
            if (checkString(value, '"', '"')) {
                return reduceString(value);
            }
            if (checkString(value, '{', '}')) {
                return reduceString(evalObject(value));
            }
            if (checkString(value, '[', ']')) {
                return reduceString(evalArray(value));
            }

            return Number(value);
        };

        const evalObject = (str) => {
            return str.split(',')
                .reduce((acc, curr) => {
                    const [key, value] = curr.split(':');
                    acc[key] = getValueByDataType(value);
                    return acc;
                }, {});
        };

        filterParams = reduceString(filterParams);
        return evalObject(filterParams);
    } */

    evalFilterParams(filterParams) {
        return JSON.parse(filterParams);
    }

    getUrlParams() {
        const url = new URL(window.document.location);
        const params = url.searchParams;

        const result = { params };

        if (params.has('filter')) {
            const filterParams = params.get('filter');
            if (filterParams.isValidJSON()) {
                result.filter = this.evalFilterParams.call(this, filterParams);
            }
        }

        return result;
    }

    getRoutes(nav) {
        return nav.reduce((acc, curr) => {
            if (curr.content) {
                return acc.concat(this.getRoutes.call(this, curr.content));
            }
            if (curr.component) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }

    renderRoute({ component, exact = true }) {
        const { filter } = this.getUrlParams.call(this);

        return <Route key={component}
            exact={exact}
            path={"/" + component}
            render={routeProps => <ComponentWrapper {...routeProps} componentName={component} filter={filter} />} />;
    }

    render() {
        const { classes, navigationConfig, shiftContent } = this.props;

        if (!navigationConfig) return null;

        const className = classNames({
            [classes.content]: !shiftContent,
            [classes.contentShift]: shiftContent
        });

        return (
            <div id="main" className={className} ref={ref => this.mainRef = ref}>
                <Container className={classes.container}>
                    {this.getRoutes.call(this, navigationConfig).map(this.renderRoute.bind(this))}
                </Container>
            </div>
        );
    }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object,
    navigationConfig: PropTypes.array,
    shiftContent: PropTypes.bool
};

export default withStyles(styles)(withRouter(Main));