import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import List from './';
import ListTitle from './listTitle';

class ListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapseOpen: false
        };

        this.expandable = props.item.content && props.item.content.length > 0;
    }

    handleClick() {
        const { onDrawerOpen, onDrawerClose, drawerOpen } = this.props;

        if (this.expandable) {
            const collapseOpen = !this.state.collapseOpen || !drawerOpen;
            this.setState({ collapseOpen });
            onDrawerOpen();
            return;
        }

        onDrawerClose();
    }

    handleSublistClick(...args) {
        const { onDrawerClose } = this.props;
        this.setState({ collapseOpen: false });
        onDrawerClose(...args);
    }

    render() {
        const { classes, item, drawerOpen, onDrawerOpen } = this.props;
        const { collapseOpen } = this.state;

        return (
            <div>
                <ListTitle item={item} collapseOpen={collapseOpen} expandable={this.expandable} onClick={this.handleClick.bind(this)} />
                {this.expandable &&
                    <Collapse className={classes.nested}
                        in={collapseOpen && drawerOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List navigationConfig={item.content}
                            onDrawerOpen={onDrawerOpen}
                            onDrawerClose={this.handleSublistClick.bind(this)}
                        />
                    </Collapse>}
            </div>
        );
    }
}

ListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    drawerOpen: PropTypes.bool,
    onDrawerOpen: PropTypes.func.isRequired,
    onDrawerClose: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(ListItem);