import ST from '../../../utils/st';

const IterateArray = ({ schema, formData }/* , stData */) => {

    /*  const newFormData = (formData || []).map(item => {
         return IterateSchema({
             schema: schema,
             formData: item
         }, stData);
     }); */

    return { schema: schema, formData: formData };
};

const IterateObject = ({ schema, formData }, stData) => {
    const newFormData = formData || {};

    schema.properties = Object.keys(schema.properties)
        .reduce((acc, key) => {
            const property = schema.properties[key];
            const result = IterateSchema({
                schema: property,
                formData: formData ? formData[key] : null
            }, stData);

            if (result.formData !== undefined && result.formData !== null) {
                newFormData[key] = result.formData;
            }

            return {
                ...acc,
                [key]: result.schema
            };
        }, {});
    return { schema, formData: newFormData };
};

const IterateSchema = ({ schema, formData }, stData) => {
    const { type } = schema;

    if (schema.default !== undefined && schema.default !== null) {
        schema.default = ST(schema.default, stData);
    }

    if (type === "array") {
        return IterateArray({ schema, formData }, stData);
    }

    if (type === "object") {
        return IterateObject({ schema, formData }, stData);
    }

    if (type === "number" || type === "integer") {
        if (formData) {
            const newFormData = Number(formData);
            return { schema, formData: newFormData };
        }
    }

    return { schema, formData };
};

export default IterateSchema;