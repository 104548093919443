import React from 'react';
import PropTypes from 'prop-types';
//import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';

import BaseInput from '../baseInput';


function Input(props) {
    const { loading, params, options, schema } = props;

    return <BaseInput
        {...params}
        options={options}
        schema={schema}
        type="string"
        InputProps={{
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        }}
    />;
}

Input.propTypes = {
    params: PropTypes.object,
    loading: PropTypes.bool,
    options: PropTypes.object,
    schema: PropTypes.object
};

export default Input;