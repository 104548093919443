import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { withStyles } from '@material-ui/core/styles';
import MuiList from '@material-ui/core/List';

import ListItem from './item';

const ROW_HEIGHT = 72;

const styles = () => ({
    virtualList: {
        flex: 1
    }
});

class VirtualList extends Component {
    render() {
        const { classes, items, componentConfig, onButtonClick } = this.props;

        const itemCount = items.length;

        return (
            <div className={classes.virtualList}>
                <AutoSizer>
                    {({ width, height }) => (
                        <MuiList>
                            <FixedSizeList
                                height={height}
                                width={width}
                                itemSize={ROW_HEIGHT}
                                itemCount={itemCount}
                            >
                                {({ index, style }) => (
                                    <ListItem
                                        item={items[index]}
                                        style={style}
                                        componentConfig={componentConfig}
                                        onButtonClick={onButtonClick}
                                    />
                                )}
                            </FixedSizeList>
                        </MuiList>
                    )}
                </AutoSizer>
            </div>
        );
    }
}

VirtualList.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    componentConfig: PropTypes.shape({
        title: PropTypes.string,
        service: PropTypes.object,
        eventHandler: PropTypes.string,
        footerDisplayTemplate: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        buttonSplitedConfig: PropTypes.shape({
            FabsConfig: PropTypes.array
        })
    })
};

export default withStyles(styles)(VirtualList);