import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

class IsLoadingInput extends Component {
    render() {
        const { required, label, type } = this.props;

        const endAdornment = <InputAdornment position="end">
            <CircularProgress />
        </InputAdornment>;

        return (
            <TextField value={""}
                label={label}
                type={type}
                fullWidth

                disabled={true}
                required={required}

                InputProps={{
                    endAdornment
                }}
            />
        );
    }
}

IsLoadingInput.propTypes = {
    required: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.string,
};

export default IsLoadingInput;