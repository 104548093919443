import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import { ArrowDownward, ArrowUpward, DragHandle } from '@material-ui/icons';

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        opacity: 0.6
    })
});

const OrderOption = props => {
    const { label, provided, snapshot, active, desc, id, onActiveToggle, onOrderDirectionToggle } = props;

    const handleToggle = () => {
        onActiveToggle(id, !active);
    };

    const handleClick = () => {
        onOrderDirectionToggle(id, !desc);
    };

    const OrderDirectionGlyph = desc ? ArrowDownward : ArrowUpward;

    const ContainerProps = {
        ref: provided.innerRef,
        ...provided.draggableProps,
        style: getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
        )
    };

    return (
        <ListItem
            dense
            ContainerProps={ContainerProps}
        >
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    checked={active}
                    onChange={handleToggle}
                />
            </ListItemIcon>
            <ListItemText>
                <IconButton disableRipple edge="end" onClick={handleClick} size="small">
                    <OrderDirectionGlyph fontSize="inherit" />
                </IconButton>
                {label}
            </ListItemText>
            <ListItemSecondaryAction {...provided.dragHandleProps}>
                <DragHandle />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

OrderOption.propTypes = {
    provided: PropTypes.object,
    snapshot: PropTypes.object,
    label: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    // index: PropTypes.number,
    active: PropTypes.bool,
    desc: PropTypes.bool,
    onActiveToggle: PropTypes.func.isRequired,
    onOrderDirectionToggle: PropTypes.func.isRequired,
};

export default OrderOption;