import axios from 'axios';
import _ from 'lodash';

import cloneDeep from './cloneDeep';
import OldRequest from './oldRequest';
import EventHandlerRegister from './eventHandlerRegister';

let PersistantDataSources = null;
let PersistantUser = null;
let PersistantAlert = null;

class ApiRequest {
    constructor(service, DataSources = PersistantDataSources, user = PersistantUser) {
        this._uri = null;
        this._original_uri = null;
        this._dataSource = null;
        this._eventHandler = null;
        this._event = null;
        this.onResponse = null;
        this.qs = null;
        this.options = {};
        this.user = user;

        this.busy = false;
        this.silent = false;

        this.setDefaultHeaders.call(this);
        this.dataSources = DataSources;

        this.service = cloneDeep(service);
    }

    static setUser(user) {
        PersistantUser = user;
    }

    static setDataSources(dataSources) {
        PersistantDataSources = dataSources;
    }

    static setAlert(alert) {
        PersistantAlert = alert;
    }

    set eventHandler(eventHandler) {
        const register = new EventHandlerRegister();
        if (!register.hasComponentHandler(eventHandler)) {
            console.error('component event handler dosnt exists: ' + eventHandler);
            return;
        }
        this._eventHandler = register.getComponentHandler(eventHandler);
    }

    set service(service) {
        if (!service) return;

        /* ######## Abwärtskompatibilität ######## */
        if (!service.dataSource) {
            service.dataSource = "Data_Server";
        }
        this._dataSource = this.dataSources[service.dataSource];
        if (!this._dataSource) {
            console.error('Invalid data source');
            return;
        }
        if (service.dataSource === "Data_Server") {
            this._dataSource.DEPRECATED = true;
            this.DEPRECATED_useOldRequest(service);
            return;
        }
        /* ######## Abwärtskompatibilität ######## */


        if (service.method) {
            this.method = service.method;
        }

        if (service.headers) {
            Object.keys(service.headers)
                .forEach(header => this.addHeader(header, service.headers[header]));
        }

        if (service.uri) {
            this.uri = service.uri;
        }

        if (service.keys) {
            this.keys = service.keys;
        }

        if (service.withKeys) {
            this.withKeys = true;
        }

        if (service.withQs) {
            if (service.orderBy) {
                service.orderBy.forEach(rule => {
                    this.orderBy = rule;
                });
            }
            if (service.with) {
                service.with.forEach(relationship => {
                    this.with = relationship;
                });
            }
        }

        if (service.onResponse) {
            this.onResponse = service.onResponse;
        }

        if (service.responseType) {
            this.responseType = service.responseType;
        }

        if (service.eventHandler) {
            this.eventHandler = service.eventHandler;
        }

        if (service.emitEvent) {
            this._event = service.emitEvent;
        }
    }

    set method(method) {
        if (!method) return;
        this.options.method = method.toLowerCase();
    }

    get method() {
        return this.options.method;
    }

    get orderBy() {
        if (this.qs && this.qs.options && this.qs.options.orderBy) {
            return this.qs.options.orderBy;
        }
        return [];
    }

    set orderBy(param) {
        const orderBy = this.orderBy;
        orderBy.push(param);

        if (!this.qs) {
            this.qs = {};
        }

        this.qs.options = {
            ...this.qs.options,
            orderBy
        };
    }

    get filter() {
        if (this.qs) {
            const filter = Object.assign({}, this.qs);
            delete filter.options;

            return filter;
        }
        return {};
    }

    set filter(param) {
        const filter = _.defaultsDeep(param, this.filter);

        if (!this.qs) {
            this.qs = {};
        }

        // this.qs = _.defaultsDeep(filter, this.qs); // removed because orderBy did not get updated properly
        this.qs = _.defaults(filter, this.qs);
    }

    get with() {
        if (this.qs && this.qs.options && this.qs.options.with) {
            return this.qs.options.with;
        }
        return [];
    }

    set with(param) {
        const withRelationship = this.with;
        withRelationship.push(param);

        if (!this.qs) {
            this.qs = {};
        }

        this.qs.options = {
            ...this.qs.options,
            with: withRelationship
        };
    }

    get queryString() {
        if (!this.qs) return "";

        const evalQueryItem = item => {
            if (typeof item === 'object') {
                return JSON.stringify(item);
            }
            return item;
        };
        return "?" + Object.keys(this.qs)
            .map(key => key + "=" + evalQueryItem(this.qs[key]))
            .join("&");
    }

    set responseType(responseType) {
        this.options.responseType = responseType;
    }

    set body(body) {
        if (!body) return;
        this.options.json = true;
        this.options.data = body;
    }

    set uri(uri) {
        if (uri.isValidURI()) {
            this._uri = uri;
            return;
        }
        let { protocol, origin, port, subpath } = this._dataSource;
        this._uri = protocol || "http://";
        this._uri += origin || "localhost";
        if (port) {
            this._uri += ":" + port;
        }
        this._uri += subpath || "";
        this._uri += uri;
        this._original_uri = this._uri;
    }

    get uri() {
        return this._uri;
    }

    setDefaultHeaders() {
        this.addHeader('Accept', "application/json");
        this.addHeader('Content-Type', "application/json;charset=utf-8");
        if (this.user && this.user.token) {
            this.addHeader('Authorization', this.user.token);
            this.addHeader('Api-Token', this.user.token);
        }
    }

    addHeader(name, value) {
        if (!this.options.headers) {
            this.options.headers = {};
        }
        this.options.headers[name] = value;
    }

    removeHeader(name) {
        delete this.options.headers[name];
    }

    addToQueryString(key, value) {
        if (!this.qs) {
            this.qs = {};
        }

        this.qs[key] = value;
    }

    addQuery(type, query) {
        if (!this.qs) {
            this.qs = {};
        }

        this.addToQuery("qtype", type);
        this.addToQuery("q", query);
    }

    addKeys(data) {
        this._uri = this._original_uri;
        if (this.keys && data) {
            if (!Array.isArray(this.keys)) {
                this.keys = [this.keys];
            }
            this._uri += this.keys.reduce((acc, curr) => {
                const keyIsNotSet = data[curr] === undefined;
                if (keyIsNotSet) {
                    return acc;
                }
                acc += "/" + data[curr];
                return acc;
            }, "");
        }
    }

    abort() {
        console.debug("abort method not assigned");
    }

    send(data) {
        this.busy = true;

        if (data && this.method && this.method.toUpperCase() !== "GET") {
            this.body = data;
        }
        if (this.withKeys) {
            this.addKeys(data);
        }

        const uri = this._uri;
        if (!uri) {
            return Promise.reject("Invalid request arguments: check value of service.uri");
        }

        this.options.url = uri + this.queryString;
        const CancelToken = axios.CancelToken;
        this.options.cancelToken = new CancelToken(c => {
            this.abort = (msg = "", { silent } = {}) => {
                this.silent = silent;
                c(msg);
            };
        });

        return axios(this.options)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    handleResponse(response) {
        this.busy = false;

        let { data } = response;
        if (typeof data === 'string') {
            if (data.isValidJSON()) {
                data = JSON.parse(data);
            }
        }

        if (this._eventHandler && this._event) {
            this._eventHandler.emit(this._event, data);
        }

        if (this.onResponse) {
            const { openNewWindow } = this.onResponse;

            if (openNewWindow) {
                /* const blob = new Blob([data], {
                  type: 'application/pdf',
                }); */
                if (!(data instanceof Blob)) {
                    throw new Error("response type is not binary");
                }
                const url = URL.createObjectURL(data);
                window.open(url);
            }
        }

        return data;
    }

    handleError(err) {
        this.busy = false;

        let message = err.message;
        if (err.response && err.response.data.message) {
            message = err.response.data.message;
        }

        if (PersistantAlert && !this.silent) {
            PersistantAlert.error(message);
        }

        return Promise.reject(err);
    }




    /* ######## Abwärtskompatibilität ######## */
    DEPRECATED_useOldRequest(service) {
        let { protocol, origin, port, subpath } = this._dataSource;
        let uri = protocol || "http://";
        uri += origin || "localhost";
        uri += ":" + (port || "80");
        uri += subpath || "";
        this.oldReq = new OldRequest(uri, this.user);
        this.send = (data) => {
            return this.oldReq.sendByService(service, data)
                .then(res => this.handleResponse(res));
        };

    }
    /* ######## Abwärtskompatibilität ######## */
}

export default ApiRequest;