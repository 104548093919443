import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';

import Glyphicon from '../glyph/';
import Form from './';


class FormContainer extends Component {
    constructor() {
        super();

        this.formRef = null;
    }

    handleClick() {
        this.formRef.submit();
    }

    render() {
        const { onClose, classes, formProps, filterData } = this.props;

        const openDrawer = Boolean(formProps);

        return (
            <Drawer className={classes.formContainer}
                anchor="bottom"
                open={openDrawer}
                onClose={onClose}
                PaperProps={{ className: classes.formContainerPaper }}>
                <IconButton onClick={onClose} className={classes.closeButton} aria-label="Close">
                    <CloseIcon />
                </IconButton>
                <Form {...formProps}
                    formRef={ref => this.formRef = ref}
                    filterData={filterData}
                    onClose={onClose} />
                <Fab color="secondary" onClick={this.handleClick.bind(this)} aria-label="submit" className={classes.fab}>
                    <Glyphicon glyph="save" />
                </Fab>
            </Drawer>
        );
    }
}

FormContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    formProps: PropTypes.object,
    filterData: PropTypes.object
};

export default withStyles(styles)(FormContainer);