import withForm from './withForm';
import withButtonConfig from './withButtonConfig';
import withComponentData from './withComponentData';

function withComponent(ParentComponent) {
    const ParentComponentWithButtonConfig = withButtonConfig(ParentComponent);
    const ParentComponentWithForm = withForm(ParentComponentWithButtonConfig);
    return withComponentData(ParentComponentWithForm);
}

export default withComponent;