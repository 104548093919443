import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import { withStyles } from '@material-ui/styles/';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = theme => ({
    header: {
        display: 'flex',
        paddingBottom: theme.spacing()
    },
    caption: {
        paddingTop: theme.spacing(2),
        flex: 1
    },
    column: {
        flex: 1
    }
});

const Views = [
    {
        name: "dayGridMonth",
        label: "Monat",
        momentUnit: "months",
        plugin: dayGridPlugin,
        labelFormat: "MMM YYYY"

    },
    {
        name: "timeGridWeek",
        label: "Woche",
        plugin: timeGridPlugin,
        momentUnit: "weeks",
        labelFormat: "LL"
    },
    {
        name: "timeGrid",
        label: "Tag",
        momentUnit: "days",
        labelFormat: "LL"
    }
];

class ViewSelect extends Component {
    constructor() {
        super();

        this.state = {
            value: 0,
            selectedDate: Moment()
        };
    }

    componentDidMount() {
        this.refreshEvents();
    }

    renderViewOption = ({ name, label }, index) => {
        return <MenuItem key={name} value={index}>
            {label}
        </MenuItem>;
    }

    refreshEvents = () => {
        const { refreshEvents } = this.props;
        const { selectedDate, value } = this.state;

        refreshEvents(selectedDate, Views[value].momentUnit);
    }

    handleChange = ({ target: { value } }) => {
        this.setState({ value });

        const { calendarApi } = this.props;
        calendarApi.changeView(Views[value].name);
    }

    handlePreviousClick = () => {
        const { calendarApi } = this.props;
        const { selectedDate, value } = this.state;

        const date = selectedDate.subtract(1, Views[value].momentUnit);
        calendarApi.changeView(Views[value].name, date.format("YYYY-MM-DD"));
        this.setState({ selectedDate: date }, this.refreshEvents);
    }

    handleNextClick = () => {
        const { calendarApi } = this.props;
        const { selectedDate, value } = this.state;

        const date = selectedDate.add(1, Views[value].momentUnit);
        calendarApi.changeView(Views[value].name, date.format("YYYY-MM-DD"));
        this.setState({ selectedDate: date }, this.refreshEvents);
    }

    handleTodayClick = () => {
        const { calendarApi } = this.props;
        const { value } = this.state;

        const selectedDate = Moment();

        if (selectedDate.isSame(this.state.selectedDate, Views[value].momentUnit)) {
            return;
        }

        calendarApi.changeView(Views[value].name, selectedDate.format("YYYY-MM-DD"));
        this.setState({ selectedDate }, this.refreshEvents);
    }

    render() {
        const { classes } = this.props;
        const { value, selectedDate } = this.state;

        const dateLabel = selectedDate.format(Views[value].labelFormat);

        return (
            <div className={classes.header}>
                <div className={classes.column}>
                    <IconButton onClick={this.handlePreviousClick}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton onClick={this.handleNextClick}>
                        <ChevronRightIcon />
                    </IconButton>
                    <Button onClick={this.handleTodayClick}>
                        heute
                    </Button>
                </div>
                <Typography
                    align="center"
                    variant="caption"
                    className={classes.caption}
                >
                    {dateLabel}
                </Typography>
                <TextField
                    className={classes.column}
                    select
                    fullWidth
                    label="Ansicht"
                    value={value}
                    onChange={this.handleChange}
                >
                    {Views.map(this.renderViewOption)}
                </TextField>
            </div>
        );
    }
}

ViewSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    calendarApi: PropTypes.object,
    refreshEvents: PropTypes.func.isRequired
};

export { Views };
export default withStyles(styles)(ViewSelect);