import React from 'react';
import PropTypes from 'prop-types';
import SchemaForm from 'react-jsonschema-form';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { FormBaseStyles } from './styles';

import Fields from './fields/';
import Widgets from './widgets/';
import CustomFieldTemplate from './templates/customFieldTemplate';
//import ArrayFileTemplate from './templates/arrayFileTemplate';
//import ArraySortOnlyTemplate from './templates/arraySortOnlyTemplate';
import CustomObjectTemplate from './templates/customObjectTemplate';
import CustomArrayTemplate from './templates/customArrayTemplate/';
import SetUndefienedToNull from './helpers/setUndefinedToNull';
import IterateSchema from './helpers/iterateSchema';

class FormBase extends React.Component {
    constructor(props) {
        super(props);

        const { formData } = props;

        this.state = {
            formData,
            diffFormData: null
        };

        this.initialFormData = formData;
        this.submitRef = null;
    }
    /*
    validateNumber(schema, formData, errors) {
        if (typeof formData === 'string') {
            formData = Number(formData.replace(",", "."));
        }
        return { formData, schema, errors };
    } */

    validate(formData, errors) {
        return errors;
    }

    handleChange = data => {
        const { onChange } = this.props;
        this.setState({ formData: data.formData });
        onChange && onChange(data);
    }

    handleSubmit = data => {
        let { onSubmit } = this.props;
        if (!onSubmit) {
            //this.context.alert.error("missing on submit config");
            return;
        }

        data.formData = SetUndefienedToNull(data.formData);

        console.log(this.omitInitialFormData(data.formData));

        onSubmit(data);
    }

    omitInitialFormData = (formData, resultPath = "") => {
        if (Array.isArray(formData)) {
            const newResultPath = resultPath + '[';
            return formData.map((val, index) => this.omitInitialFormData(val, newResultPath + index + ']'));
        }

        if (typeof formData === 'object') {
            const currInitialFormData = _.get(this.initialFormData, resultPath);
            const keys = _.keys(currInitialFormData);
            return _.omit(formData, keys);
        }

        return formData;
    }

    /* getArrayTemplate() {
        switch (this.props.arrayTemplate) {
            case "sortOnly": return ArraySortOnlyTemplate;
            case "file": return ArrayFileTemplate;
            default: return undefined;
        }
    } */

    render() {
        const { formRef, classes, children, filterData, uiSchema } = this.props;

        const clonedSchema = _.cloneDeep(this.props.schema);
        const clonedFormData = _.cloneDeep(this.state.formData);

        const { schema, formData } = IterateSchema({ schema: clonedSchema, formData: clonedFormData }, { filterData });

        //const schema = this.props.schema;
        //const formData = this.state.formData;

        if (!schema) return null;

        const FormProps = {
            onSubmit: this.handleSubmit,
            onChange: this.handleChange,
            formData,
            schema,
            uiSchema,
            fields: Fields,
            widgets: Widgets,
            //validate: this.validate.bind(this, schema),
            FieldTemplate: CustomFieldTemplate,
            ArrayFieldTemplate: CustomArrayTemplate/* : this.getArrayTemplate.call(this) */,
            ObjectFieldTemplate: CustomObjectTemplate,
            showErrorList: false,
            ref: formRef,
            formContext: {
                filterData
            }
        };

        return (
            <SchemaForm {...FormProps}>
                {children ? children : <button type="submit" className={classes.hidden} />}
            </SchemaForm>
        );
    }
}

FormBase.contextTypes = {
    alert: PropTypes.object
};

FormBase.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.object,
    schema: PropTypes.object,
    uiSchema: PropTypes.object,
    formData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    filterData: PropTypes.object,
    formRef: PropTypes.func
};

export default withStyles(FormBaseStyles)(FormBase);