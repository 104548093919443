import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withWidth from '@material-ui/core/withWidth';

import MobileView from './mobileView';
import DesktopView from './desktopView';
import CalendarEventsSource from './utils/calendarEventSource';

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: null
        };

        const { componentConfig: { services } } = props;

        this.dataSource = new CalendarEventsSource(services);
    }

    refreshEvents = (selectedDate, momentUnit) => {

        const start = selectedDate.clone()
            .subtract(1, momentUnit)
            .startOf(momentUnit)
            .format("YYYY-MM-DD");

        const end = selectedDate.clone()
            .add(1, momentUnit)
            .endOf(momentUnit)
            .format("YYYY-MM-DD");

        return this.dataSource.fetchEvents(start, end)
            .then(events => this.setState({ events }));
    }

    render() {
        const { width } = this.props;
        const { events } = this.state;

        if (width === "xs") {
            return <MobileView events={events} refreshEvents={this.refreshEvents} />;
        }
        return <DesktopView events={events} refreshEvents={this.refreshEvents} />;
    }
}

Calendar.propTypes = {
    componentConfig: PropTypes.object,
    width: PropTypes.string
};

export default withWidth()(Calendar);