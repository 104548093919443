import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Dialog from '../../dialog/';

import ST from '../../../utils/st';
import ApiRequest from '../../../utils/apiRequest';

import { MergeDefaultButtonConfig, SplitConfig, FillServicesWithDefaultServiceByComponentConfig } from '../../../utils/buttonHelper';

function withButtonConfig(ParentComponent) {
    return withRouter(class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                onClickConfig: null
            };

            this.buttonConfig = null;
            this.buttonSplitedConfig = {
                FabsConfig: null,
                NormalButtonConfig: null
            };

            this.componentConfig = props.componentConfig;
            const { buttons, defaultButtons } = this.componentConfig;


            this.initButtonConfig.call(this, buttons, defaultButtons);
        }

        initButtonConfig(buttonConfig, defaultButtonConfig) {
            this.buttonConfig = MergeDefaultButtonConfig(buttonConfig, defaultButtonConfig);
            this.buttonConfig = FillServicesWithDefaultServiceByComponentConfig(this.buttonConfig, this.componentConfig);
            const { FabsConfig, NormalButtonConfig } = SplitConfig(this.buttonConfig);
            this.buttonSplitedConfig = { FabsConfig, NormalButtonConfig };

            this.componentConfig.buttonSplitedConfig = this.buttonSplitedConfig;
        }

        clickButton = async (onClickConfig, data) => {
            const { form, service, component, dialog } = onClickConfig;
            const { openForm, history } = this.props;

            if (dialog) {
                this.setState({ onClickConfig, data });
                return;
            }

            let serviceResult;
            if (service) {
                const req = new ApiRequest(service);
                serviceResult = await req.send(data);
            }

            if (form && openForm) {
                form.formData = serviceResult;
                openForm(form);
            }

            if (component) {
                const filterData = ST(component.filter, { data });
                const filter = "?filter=" + JSON.stringify(filterData);
                history.push("/" + component.name + filter);
            }
        }

        handleDialogClose = () => {
            this.setState({ onClickConfig: null, data: null });
        }

        handleDialogSubmit = () => {
            const { data, onClickConfig: { ...onClickConfig } } = this.state;
            delete onClickConfig.dialog;

            this.clickButton(onClickConfig, data);
            this.handleDialogClose();
        }

        render() {
            const { onClickConfig } = this.state;
            const { ...others } = this.props;
            delete others.componentConfig;

            const dialogOpen = Boolean(onClickConfig);

            return [
                <ParentComponent
                    {...others}
                    key="parent-component"
                    componentConfig={this.componentConfig}
                    buttonConfig={this.buttonConfig}
                    buttonSplitedConfig={this.buttonSplitedConfig}
                    clickButton={this.clickButton}
                />,
                dialogOpen && <Dialog
                    key="dialog"
                    dialogOpen={dialogOpen}
                    {...onClickConfig.dialog}

                    onSubmit={this.handleDialogSubmit}
                    onClose={this.handleDialogClose}
                />];
        }
    });
}

export default withButtonConfig;