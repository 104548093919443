import React, { Component } from 'react';
import PropTypes from 'prop-types';

//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';

import ListMenuItem from './item';

class ListMenu extends Component {
    constructor() {
        super();

        this.state = {
            anchorEl: null
        };
    }

    handleClick(evt) {
        this.setState({ anchorEl: evt.currentTarget });
    }

    handleClose() {
        this.setState({ anchorEl: null });
    }

    renderListMenuItem(buttonConfig, index) {
        const { onButtonClick, item } = this.props;

        return <ListMenuItem key={index} buttonConfig={buttonConfig} onButtonClick={onButtonClick} item={item} onMenuClose={this.handleClose.bind(this)} />;
    }

    render() {
        const { anchorEl } = this.state;
        const { buttonConfig } = this.props;

        const openMenu = Boolean(anchorEl);

        return (
            <div>
                <IconButton edge="end" aria-label="open-menu" onClick={this.handleClick.bind(this)}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl}
                    open={openMenu}
                    onClose={this.handleClose.bind(this)}
                >
                    {buttonConfig.map(this.renderListMenuItem.bind(this))}
                </Menu>
            </div>
        );
    }
}

ListMenu.contextTypes = {
    alert: PropTypes.object
};

ListMenu.propTypes = {
    buttonConfig: PropTypes.array.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    item: PropTypes.object
};

export default ListMenu;