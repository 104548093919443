import cloneDeep from '../cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import DefaultButtons from './defaultButtons';

function MergeDefaultButtonConfig(buttonConfig, defaultButtonConfig = {}) {
    const defaultButtons = cloneDeep(DefaultButtons);

    let mergedButtonConfig = Object.keys(defaultButtons)
        .reduce((acc, curr) => {
            if (defaultButtonConfig[curr] === false) {
                return acc;
            }

            if (typeof defaultButtonConfig[curr] === 'object') {
                acc.push(defaultsDeep(defaultButtonConfig[curr], defaultButtons[curr]));
                return acc;
            }

            acc.push(defaultButtons[curr]);
            return acc;
        }, []);

    if (buttonConfig) {
        mergedButtonConfig = mergedButtonConfig.concat(buttonConfig);
    }
    return mergedButtonConfig;
}

function SplitConfig(buttonConfig) {
    const FabsConfig = [];
    const NormalButtonConfig = [];

    buttonConfig.forEach(config => {
        if (config.isFab) {
            FabsConfig.push(config);
            return;
        }
        NormalButtonConfig.push(config);
    });

    return { FabsConfig, NormalButtonConfig };
}

function FillServicesWithDefaultServiceByComponentConfig(buttonConfig, componentConfig) {
    if (!componentConfig.service) return buttonConfig;

    return buttonConfig.map(conf => {
        if (conf.onClick && conf.onClick.service) {
            conf.onClick.service = defaultsDeep(conf.onClick.service, componentConfig.service);
        }
        return conf;
    });

}

export { SplitConfig, MergeDefaultButtonConfig, DefaultButtons, FillServicesWithDefaultServiceByComponentConfig };