let singleton = null;

class EventHandlerRegister {
    constructor() {
        if (singleton) return singleton;
        singleton = this;

        this.components = {};
    }

    /* ########## Components ########## */

    hasComponentHandler(name) {
        return this.components.hasOwnProperty(name);
    }

    addComponentHandler(name, handler) {
        this.components[name] = handler;
    }

    getComponentHandler(name) {
        return this.components[name];
    }
}

export default EventHandlerRegister;