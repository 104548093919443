import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import BaseInput from '../baseInput';

function withSimilarProps(ParentComponent) {
    class WithSimilarProps extends Component {

        handleChange(value) {
            const { databaseDateTimeFormat } = this.context.config;
            const { onChange } = this.props;

            const utcValue = Moment(value).utc().format(databaseDateTimeFormat);

            onChange(utcValue);
        }

        render() {
            const { value, ...others } = this.props;

            delete others.onChange;

            return <ParentComponent
                value={value ? Moment.utc(value).local() : null}
                clearable
                showTodayButton
                onChange={this.handleChange.bind(this)}
                TextFieldComponent={BaseInput}
                {...others}
            />;
        }
    };

    WithSimilarProps.defaultProps = {
        value: null
    };

    WithSimilarProps.contextTypes = {
        config: PropTypes.object.isRequired
    };

    WithSimilarProps.propTypes = {
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        autofocus: PropTypes.bool,
        id: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
        options: PropTypes.object
    };

    return WithSimilarProps;
}

export default withSimilarProps;