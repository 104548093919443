import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormConfig from './loginSchemaForm.json';

import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormBase from '../form/base';

import ApiRequest from '../../utils/apiRequest';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true
        };

        this.formRef = null;
    }

    componentDidMount() {
        let jwt = localStorage.getItem('manager_login_jwt_token');

        if (jwt) {
            const { config: { systemServices: { verifyUser } } } = this.context;
            const request = new ApiRequest(verifyUser);
            request.addHeader('Authorization', jwt);
            request.addHeader('Api-Token', jwt);
            request.send()
                .then(this.handleResponse.bind(this));
        }
    }

    handleClick() {
        this.formRef.submit();
    }

    handleSubmit({ formData }) {
        const { config: { systemServices: { login } } } = this.context;
        const { username, password, rememberMe } = formData;

        const request = new ApiRequest(login);
        request.send({ username, password })
            .then(res => {
                if (rememberMe) {
                    localStorage.setItem('manager_login_jwt_token', res.token);
                } else {
                    localStorage.removeItem('manager_login_jwt_token');
                }
                return res;
            })
            .then(this.handleResponse.bind(this));

    }

    handleResponse(res) {
        const { onLogin } = this.props;
        onLogin(res);
    }

    render() {
        return (
            <Dialog open={true} aria-labelledby="login-form-dialog-title">
                <DialogTitle id="login-form-dialog-title">Login</DialogTitle>
                <DialogContent>
                    <FormBase onSubmit={this.handleSubmit.bind(this)}
                        formRef={ref => this.formRef = ref}
                        schema={FormConfig.schema}
                        uiSchema={FormConfig.uiSchema} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClick.bind(this)} color="primary">
                        einloggen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

Login.contextTypes = {
    config: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

Login.propTypes = {
    onLogin: PropTypes.func.isRequired
};

export default Login;