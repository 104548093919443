import React from "react";
import PropTypes from "prop-types";

import { asNumber, guessType } from "react-jsonschema-form/lib/utils";

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const nums = new window.Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, value) {
    // "enum" is a reserved word, so only "type" and "items" can be destructured
    const { type, items } = schema;
    if (value === "") {
        return undefined;
    } else if (type === "array" && items && nums.has(items.type)) {
        return value.map(asNumber);
    } else if (type === "boolean") {
        return value === "true";
    } else if (type === "number") {
        return asNumber(value);
    }

    // If type is undefined, but an enum is present, try and infer the type from
    // the enum values
    if (schema.enum) {
        if (schema.enum.every(x => guessType(x) === "number")) {
            return asNumber(value);
        } else if (schema.enum.every(x => guessType(x) === "boolean")) {
            return value === "true";
        }
    }

    return value;
}

function getValue(event, multiple) {
    if (multiple) {
        return [].slice
            .call(event.target.options)
            .filter(o => o.selected)
            .map(o => o.value);
    }
    return event.target.value;
}

function SelectWidget(props) {
    const {
        schema,
        id,
        options,
        value,
        required,
        disabled,
        readonly,
        multiple,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        placeholder
    } = props;

    const { enumOptions, enumDisabled } = options;
    const emptyValue = multiple ? [] : "";

    const handlers = {};

    if (onBlur) {
        handlers.onBlur = evt => {
            const newValue = getValue(evt, multiple);
            onBlur(id, processValue(schema, newValue));
        };
    }

    if (onFocus) {
        handlers.onFocus = evt => {
            const newValue = getValue(evt, multiple);
            onFocus(id, processValue(schema, newValue));
        };
    }

    handlers.onChange = evt => {
        const newValue = getValue(evt, multiple);
        onChange(processValue(schema, newValue));
    };

    return (
        <TextField
            id={id}
            multiple={multiple}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            fullWidth

            {...handlers}

            select
            label={schema.title}
            helperText={placeholder}
        >
            {enumOptions.map(({ value, label }, i) => {
                const disabled = enumDisabled && enumDisabled.indexOf(value) !== -1;
                return (
                    <MenuItem key={i} value={value} disabled={disabled}>
                        {label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
}

SelectWidget.defaultProps = {
    autofocus: false,
};

SelectWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
        enumOptions: PropTypes.array,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default SelectWidget;