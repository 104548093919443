import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Menu from './menu';
import SecondaryLisItemContent from './secondaryListItemContent';

import ST from '../../utils/st';

const styles = () => ({
    primaryText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

class ListItem extends Component {

    handleButtonClick = (...args) => {
        const { onButtonClick } = this.props;
        onButtonClick(...args);
    }

    render() {
        const { classes, style, item, componentConfig } = this.props;

        const { primary, secondary, subTitle } = ST(componentConfig.itemDisplayTemplate, {
            Moment,
            item
        });

        const secondaryText = <SecondaryLisItemContent secondary={secondary} subTitle={subTitle} />;

        return (
            <MuiListItem style={style}>
                <ListItemText primary={primary} secondary={secondaryText} className={classes.primaryText} />
                <Menu buttonConfig={componentConfig.buttonSplitedConfig.NormalButtonConfig}
                    onButtonClick={this.handleButtonClick} item={item} />
            </MuiListItem>
        );
    }
}

ListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    componentConfig: PropTypes.shape({
        buttonSplitedConfig: PropTypes.object,
        itemDisplayTemplate: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ])
    }).isRequired,
    onButtonClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    item: PropTypes.object.isRequired
};

export default withStyles(styles)(ListItem);