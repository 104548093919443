import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Glyph from '../../glyph/';
import Popper from './popper';

const Sort = props => {
    const { orderOptions, filterData, onSubmit } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    if (orderOptions?.length <= 0) {
        return null;
    }

    const handleClick = ({ target }) => {
        setAnchorEl(target);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Tooltip title="Sortieren">
                <IconButton onClick={handleClick}>
                    <Glyph glyph="sort" />
                </IconButton>
            </Tooltip>
            <Popper
                anchorEl={anchorEl}
                onClose={handleClose}
                orderOptions={orderOptions.map((orderOption, index) => ({ ...orderOption, id___: index }))}
                filterData={filterData}
                onSubmit={onSubmit}
            />
        </Fragment>
    );
};

Sort.propTypes = {
    orderOptions: PropTypes.array,
    filterData: PropTypes.object,
    onSubmit: PropTypes.func
};

export default Sort;